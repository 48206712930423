<template>
  <base-container>
    <base-heading>
      <div class="title-icon-with-background">
        <base-icon>bolt</base-icon>
      </div>
      Quick Actions
    </base-heading>
    <div class="flex flex-col space-y-betweenElements">
      <base-button type="mixed" look="secondary" @click="onListCustomers()">
        <span>Kunden</span>
        <baseIcon class="font-bold">format_list_bulleted</baseIcon>
      </base-button>
      <base-button v-if="KeycloakService.isManager()" type="mixed"  look="secondary" @click="onCreateCustomer()">
        <span>Neuer Kunde Erfassen</span>
        <baseIcon>person</baseIcon>
      </base-button>
      <base-button v-if="TenantSettings.isProjectOriented() && KeycloakService.isManager()" type="mixed"  look="secondary" @click="onCreateProject()">
        <span>Neues Projekt Erfassen</span>
        <baseIcon>home_repair_service</baseIcon>
      </base-button>
    </div>
  </base-container>
</template>

<script setup lang="ts">

import router, { routeNames } from '@/router'
import { KeycloakService } from '@/service/keycloakService'
import { TenantSettings } from '@/stores/TenantSettings'

async function onListCustomers() {
  if (TenantSettings.isProjectOriented()) await router.push({name: routeNames.CUSTOMERS});
  else await router.push({name: routeNames.PROJECTS});
}

async function onCreateProject() {
  await router.push({name: routeNames.CREATE_PROJECT});
}

async function onCreateCustomer() {
  await router.push({name: routeNames.CREATE_CUSTOMER});
}

</script>

<style scoped>

</style>
