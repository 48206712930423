<template>
  <base-container>
    <base-heading>
      <div class="title-icon-with-background">
        <base-icon>assignment</base-icon>
      </div>
      Kategorien / Beschreibung
    </base-heading>

    <div v-if="material" class="flex selectable mb-betweenElements"> <!-- @click="openModal(sentence)" -->
      {{material.invoiceInscription}}
      <base-icon class="ml-auto cursor-pointer text-primary" @click="onDelete">delete</base-icon>
    </div>

    <div class="flex md:flex-row flex-col mb-betweenElements">
      <base-button v-if="!material" type="icon" class="md:mr-auto md:mb-0 mb-8" look="secondary" @click="openModal([])">
        <base-icon class="mr-5">add</base-icon>
        <span>Material hinzufügen</span>
      </base-button>
      <base-button type="icon" class="md:ml-auto" look="secondary" v-if="material || (!material && !descriptionVisible)" @click="material ? convertToText() : descriptionVisible = true">
        <base-icon class="mr-5">edit</base-icon>
        <span>{{ material? 'Kategorien zu Text konvertieren' : 'Beschreibung hinzufügen' }}</span>
      </base-button>
    </div>

<!--    <FormKit v-if="descriptionVisible" type="textarea" name="description" id="description" label="Detailbeschreibung" v-model="descriptionText" />-->
    <div v-if="descriptionVisible" class="flex flex-row">
      <div class="w-full">
        <FormKit type="textarea" label="Detailbeschreibung" v-model="descriptionText" />
      </div>
      <button class="pl-betweenElements" @click="openTranscriptionModal">
        <base-icon size="iconSizeLarge">mic</base-icon>
      </button>
    </div>

    <categories-modal ref="modalRef" :type="ReportType.MATERIAL" @receive-modal-categories="receiveCategoriesFromModal"></categories-modal>
    <transcription-modal ref="transcriptionModalRef"
                         :existing-text="descriptionText"
                         @on-transcribed="onTranscribed" />
  </base-container>
</template>

<script setup lang="ts">
import { defineExpose, ref, watch } from 'vue'
import CategoriesModal from '@/components/workReportPosition/CategoriesModal.vue'
import type Category from '@/model/Category'
import BaseContainer from '@/components/base/BaseContainer.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import { ReportType } from '@/model/Type'
import { useToast } from 'vue-toast-notification'
import TranscriptionModal from '@/components/workReportPosition/TranscriptionModal.vue'

const props = defineProps<{
  existingCategory: Category | undefined;
  existingDescription: string;
}>();

const material = ref<Category | null>(null);
const descriptionVisible = ref(false);
const descriptionText = ref('');

const emits = defineEmits(['receive-category']);

const $toast = useToast();

watch(() => props.existingCategory, newVal => {
  if (!newVal || newVal.id == undefined) return;
  material.value = newVal;
}, {
  immediate: true
});

watch(() => props.existingDescription, newVal => {
  if (!newVal) return;
  descriptionText.value = newVal;
  descriptionVisible.value = true;
}, {
  immediate: true
});

function onDelete() {
  material.value = null;
  emitData()
}

function receiveCategoriesFromModal(categories: Category[]) {
  if (categories.length == 0) {
    $toast.info('Keine Kategorie angegeben');
    return;
  }
  if (categories.length > 1) {
    $toast.error('Zu viele Kategorien angegeben');
    return;
  }
  material.value = categories[0];
  emitData()
}

watch(() => descriptionText.value, newVal => {
  if (newVal == undefined) return;
  emitData()
}, {
  immediate: true
});

// Reference to the Modal component
const modalRef = ref(CategoriesModal);

const openModal = (categories: Category[]) => {
  if (modalRef.value) {
    modalRef.value.openModal(categories);
  }
};

function convertToText() {
  descriptionVisible.value = true;
  descriptionText.value = material.value ? material.value.invoiceInscription : '';
}


// emitting selected categories to parent component
function emitData() {
  emits('receive-category', material.value, descriptionText.value);
}

const transcriptionModalRef = ref(TranscriptionModal);
const openTranscriptionModal = () => {
  if (transcriptionModalRef.value) {
    transcriptionModalRef.value.openModal();
  }
};

function onTranscribed(text: string) {
  if (!text || text == '') return;
  descriptionText.value = text;
}

defineExpose({
  emitCategories: emitData
})

</script>

<style scoped>

</style>