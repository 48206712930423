<template xmlns="http://www.w3.org/1999/html">
  <div v-if="!modalOpen" @click="onMenu" class="overflow-x-hidden fixed top-0 left-0 h-screen w-screen bg-black/50 flex justify-end z-20">
    <div @click.stop class="w-screen md:w-[400px] md:m-betweenElements rounded-none md:rounded-md bg-grey-light p-outerFrameBorder md:border md:border-primary flex flex-col">
      <div class="flex flex-row mb-8">
        <button class="text-primary" @click="onLogout">
          <base-icon size="text-iconSizeLarge">lock</base-icon>
        </button>
        <button class="hover:cursor-pointer text-primary ml-auto" @click="onMenu">
          <base-icon size="text-iconSizeLarge" @click="onMenu">close</base-icon>
        </button>
      </div>

      <div class="overflow-y-auto flex-grow">
        <div class="menu-item" @click="router.push({name: routeNames.DASHBOARD}); onMenu()">Dashboard</div>
        <div class="menu-item" @click="router.push({name: routeNames.CUSTOMERS}); onMenu()" v-if="TenantSettings.isProjectOriented()">Kunden</div>
        <div class="menu-item" @click="router.push({name: routeNames.PROJECTS}); onMenu()">{{ TenantSettings.isProjectOriented() ? 'Alle Projekte' : 'Kunden' }}</div>
        <div class="menu-item" @click="router.push({name: routeNames.CATEGORIES_ROOT}); onMenu()" v-if="KeycloakService.isManager()">{{ TenantSettings.isTransport() ? 'Preisvorlagen' : 'Kategorien' }}</div>
        <div class="menu-item" @click="router.push({name: routeNames.EDIT_COMPANY}); onMenu()" v-if="KeycloakService.isAdmin()">Firma</div>
        <div class="menu-item" @click="router.push({name: routeNames.PRESETS_AND_TYPES}); onMenu()" v-if="KeycloakService.isAdmin()">{{ TenantSettings.isTransport() ? 'Einheiten und Typen' : 'Vorlagen und Typen' }}</div>
        <div class="menu-item" @click="router.push({name: routeNames.SETTINGS}); onMenu()" v-if="KeycloakService.isAdmin()">Einstellungen</div>
      </div>

      <div class="bottom-0 cursor-pointer text-bodyMediumBold hover:text-primary transition-colors" @click="openModal">Mandant wechseln</div>

    </div>
  </div>

  <confirmation-modal ref="confirmDeletionModal"
                      title="Mandant wechseln"
                      confirm-text="Mandant wechseln"
                      @on-confirm="onTenantLogout"
                      @close-action="() => {modalOpen = false}">
    Wollen Sie den Mandant wirklich wechseln? Sie werden ausgeloggt und müssen einen Mandantenschlüssel eingeben.
  </confirmation-modal>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'
import { routeNames } from '@/router'
import { KeycloakService } from '@/service/keycloakService'
import ConfirmationModal from '@/components/base/ConfirmationModal.vue'
import { ref } from 'vue'
import { TenantSettings } from '@/stores/TenantSettings'

const emits = defineEmits(['deactivate-menu']);
const router = useRouter();

const confirmDeletionModal = ref(ConfirmationModal);
const modalOpen = ref(false);

const onMenu = () => {
  emits('deactivate-menu');
};

function onLogout() {
  sessionStorage.clear();
  KeycloakService.logout();
}

function onTenantLogout() {
  localStorage.clear();
  onLogout();
}

const openModal = () => {
  modalOpen.value = true;
  if (confirmDeletionModal.value) {
    confirmDeletionModal.value.openModal();
  }
};

</script>

