<template>
  <base-container>
    <base-heading>
      <div class="title-icon-with-background">
        <base-icon>engineering</base-icon>
      </div>
      Arbeiter
    </base-heading>

    <worker-form-card v-for="(form, index) of allWorkerFormsInput" :key="index"
                      :index="index"
                      :worker="form"
                      :presets="presets"
                      :preset-options="presetOptions"
                      :extended="extendedWorkerForm == index"
                      :delete-enabled="allWorkerFormsInput.length > 1"
                      @update-worker="updateWorker"
                      @on-extend="onExtendWorker"
                      @on-delete="onDeleteWorker">
    </worker-form-card>

    <div class="flex md:flex-row flex-col">
      <base-button class="mt-betweenElements md:mr-auto md:mb-0 mb-8" type="icon" look="secondary" @click="onAddWorkerNew()">
        <base-icon class="mr-5">add</base-icon>
        <span>Weiterer Arbeiter hinzufügen</span>
      </base-button>
    </div>

  </base-container>
</template>

<script setup lang="ts">
import { onMounted, defineExpose, ref, watch } from 'vue'
import { API, PathSegment } from '@/client/axios'
import type WorkerPreset from '@/model/WorkerPreset'
import type { Worker } from '@/model/WorkReportPosition'
import { useToast } from 'vue-toast-notification'
import { Units, Type } from '@/model/Type'
import type { WorkerForm } from '@/model/WorkerForm'
import WorkerFormCard from '@/components/workReportPosition/WorkerFormCard.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import BaseContainer from '@/components/base/BaseContainer.vue'

const props = defineProps<{
  existingWorkers: Worker[];
}>();

const workers = ref<Worker[]>([]);
const presets = ref<WorkerPreset[]>([]);
const presetOptions = ref<{value: string, label: string}[]>([{value: '', label: "Keine Vorlage"}]);
const selectedPresetId = ref('');
const workerName = ref('');
const hoursWorked = ref(0.0);
const pricePerHour = ref(0.0);
const workerQuantity = ref(1);

const allWorkerFormsInput = ref<WorkerForm[]>([]);
const allWorkerFormsOutput = ref<WorkerForm[]>([]);
const extendedWorkerForm = ref(0);
const emits = defineEmits(['receive-workers']);

const $toast = useToast();

watch(() => props.existingWorkers, newVal => {
  if (!newVal || newVal.length == 0) return;
  const workerForms: WorkerForm[] = []
  newVal.forEach(worker => {
    workerForms.push({id: worker.id, workReportPositionId: worker.workReportPositionId, presetId: '', name: worker.name, pricePerHour: worker.pricePerHour, hoursWorked: worker.hoursWorked, quantity: worker.quantity});
  })
  allWorkerFormsInput.value = workerForms;
  extendedWorkerForm.value = -1;
}, {
  immediate: true
});

function updateWorker(index: number, worker: WorkerForm) {
  allWorkerFormsOutput.value[index] = worker;
}

function onExtendWorker(index: number) {
  if (extendedWorkerForm.value == index) extendedWorkerForm.value = -1;
  else extendedWorkerForm.value = index;
}

function onAddWorkerNew() {
  allWorkerFormsOutput.value.push(getStandardWorkerForm());
  allWorkerFormsInput.value.push(getStandardWorkerForm());
  extendedWorkerForm.value = allWorkerFormsInput.value.length - 1;
}

function onDeleteWorker(index: number) {
  if (index < 0 || index >= allWorkerFormsInput.value.length) {
    $toast.error('Arbeiter nicht gefunden (index out of range)');
    return;
  }
  if (allWorkerFormsInput.value.length == 1) {
    $toast.error('Löschen nicht möglich. Mindestens ein Arbeiter muss vorhanden sein');
    return;
  }

  allWorkerFormsOutput.value.splice(index, 1);
  allWorkerFormsInput.value = [...allWorkerFormsOutput.value];
}

function convertFormsToWorkers(): boolean {
  for (const form of allWorkerFormsOutput.value) {
    if (form.presetId) {
      const preset: WorkerPreset | undefined = presets.value.find(p => p.id == form.presetId);
      if (!preset) {
        $toast.error('Unbekannte Vorlage');
        console.error('unknown preset');
        return false;
      }
      const worker: Worker = {
        id: form.id,
        workReportPositionId: form.workReportPositionId,
        name: preset.name,
        pricePerHour: preset.pricePerHour,
        hoursWorked: form.hoursWorked,
        quantity: preset.quantity,
        total: 0,
      };
      workers.value.push(worker);
    } else {
      const worker: Worker = {
        id: form.id,
        workReportPositionId: form.workReportPositionId,
        name: form.name,
        pricePerHour: form.pricePerHour,
        hoursWorked: form.hoursWorked,
        quantity: form.quantity,
        total: 0
      };
      workers.value.push(worker);
    }
  }
  return true;
}

function emitWorkers() {
  if (!convertFormsToWorkers()) return;
  emits('receive-workers', workers.value);
}

async function loadPresets() {
  const response = await API.getDataObject<WorkerPreset[]>(PathSegment.WORK_REPORT_POSITION_PRESET, '', 'Arbeitervorlagen');
  if (!response) return;
  presetOptions.value = [presetOptions.value[0], ...response.map(p => {
    return { value: p.id, label: `${p.name} (${p.pricePerHour} ${Type.getImmutableUnit(Units.SWISS_FRANCS).abbreviation()}/${Type.getImmutableUnit(Units.HOURS).abbreviation()})` };
  })];
  presets.value = response;
  const preSelected = presets.value.find(p => p.defaultSelected);
  selectedPresetId.value = preSelected ? preSelected.id : '';
  if (allWorkerFormsInput.value.length == 0) allWorkerFormsInput.value.push(getStandardWorkerForm());
}

function inputsFilled() {
  if (selectedPresetId.value) {
    return hoursWorked.value > 0;
  } else {
    return (workerName.value != '' && hoursWorked.value > 0 && pricePerHour.value > 0 && workerQuantity.value > 0);
  }
}

onMounted(async () => {
  await loadPresets();
  if (!props.existingWorkers) extendedWorkerForm.value = 0;
});

function getStandardWorkerForm(): WorkerForm {
  return {id: '', workReportPositionId: '', presetId: selectedPresetId.value ? selectedPresetId.value : '', name: '', hoursWorked: 0, pricePerHour: 0, quantity: 1};
}

defineExpose({
  emitWorkers
});

</script>

<style scoped>

</style>
