<template>
  <div class="flex flex-col justify-between space-y-[4.5px] cursor-pointer w-[28px]" :class="{'mt-4': props.active}">
    <div class="bar" :class="dynamicTopBarClasses"></div>
    <div v-if="!active" class="bar"></div>
    <div class="bar" :class="dynamicBottomBarClasses"></div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"

const props = defineProps({
  active: {
    type: Boolean,
    required: false,
    default: false
  }
})

const dynamicTopBarClasses = computed(() => {
    if (props.active) {
        return 'rotate-45 translate-y-[110%]';
    } else {
        return '';
    }
});

const dynamicBottomBarClasses = computed(() => {
    if (props.active) {
        return 'rotate-135 translate-y-[-110%]';
    } else {
        return '';
    }
});
</script>

<style scoped>
.bar {
  @apply bg-primary h-[3.5px] w-full;
}
</style>
