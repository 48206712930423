import pdfMake from 'pdfmake/build/pdfmake';

import { DateConverter } from '@/client/DateConverter'
import { useToast } from 'vue-toast-notification'
import { Utils } from '@/client/utils'
import { Units, Type } from '@/model/Type'
import type InvoiceInfo from '@/model/InvoiceInfo'
import type { PdfFormatInfo } from '@/model/PdfFormatInfo'
import cutLinePng from '@/assets/cut_line.png'
import { API } from '@/client/axios'
import type Customer from '@/model/Customer'
import type Company from '@/model/Company'
import { TenantSettings } from '@/stores/TenantSettings'

(pdfMake as any).fonts = {
  Roboto: {
    normal: `${API.apiBaseUrl}fonts/Roboto-Regular.ttf`,
    bold: `${API.apiBaseUrl}fonts/Roboto-Medium.ttf`,
    italics: `${API.apiBaseUrl}fonts/Roboto-Italic.ttf`,
    bolditalics: `${API.apiBaseUrl}fonts/Roboto-MediumItalic.ttf`,
  }
};

export class PdfService {
  static async generateInvoice(invoiceInfo: InvoiceInfo, pdfFormatInfo: PdfFormatInfo) {

    try {
      const docName = `rechnung_${DateConverter.getCurrentLocalDate().replace(/\./g, '_')}_${invoiceInfo.project.name.toLocaleLowerCase().replace(/[^a-zA-Z0-9 ]/g, '').replace(/ /g, '_')}.pdf`;

      const docDefinition: any = {
        info: {
          title: docName
        },
        content: [],
        defaultStyle: {
          columnGap: 20,
        }
      };

      if (invoiceInfo.base64Logo) docDefinition.content.push(this.getLogo(invoiceInfo.base64Logo, invoiceInfo.settings.logoHeight, invoiceInfo.settings.distanceTopLogo));
      docDefinition.content.push(this.getAddresses(invoiceInfo.company, invoiceInfo.customer, invoiceInfo.settings.distanceLogoAddress, invoiceInfo.settings.distanceBetweenAddresses));
      docDefinition.content.push(this.standardText(`${invoiceInfo.company.address.city}, ${DateConverter.getCurrentLocalDate()}`, 0, 20))
      docDefinition.content.push({ text: invoiceInfo.settings.title, fontSize: 13, margin: [0, 0, 0, 10] });
      if (invoiceInfo.settings.header) docDefinition.content.push(this.standardText(invoiceInfo.settings.header));
      docDefinition.content.push(this.getTableTitle(invoiceInfo.settings.workTableTitle, pdfFormatInfo.pageBreakBeforeWork ? 0 : pdfFormatInfo.marginBeforeWork, pdfFormatInfo.pageBreakBeforeWork, invoiceInfo.calculation.workReportPositions.length > 0));
      docDefinition.content.push(this.getWorkPositionTable(invoiceInfo, pdfFormatInfo.marginAfterWork));
      docDefinition.content.push(this.getTableTitle(invoiceInfo.settings.materialTableTitle.split(';;;')[0], pdfFormatInfo.pageBreakBeforeMaterial ? 0 : pdfFormatInfo.marginBeforeMaterial, pdfFormatInfo.pageBreakBeforeMaterial, invoiceInfo.calculation.materialReportPositions.length > 0));
      docDefinition.content.push(this.getMaterialPositionTable(invoiceInfo, pdfFormatInfo.marginAfterMaterial));
      docDefinition.content.push(this.getTableTitle('Gesamtbetrag', 0, pdfFormatInfo.totalAndQrBillOnSameSite));
      docDefinition.content.push(this.getTotalCostTable(invoiceInfo));
      docDefinition.content.push(this.getPaymentConditionsText(invoiceInfo));
      if (invoiceInfo.settings.footer) docDefinition.content.push(this.standardText(invoiceInfo.settings.footer, 20));
      docDefinition.content.push(this.getQrBill(invoiceInfo.base64QrBill, !pdfFormatInfo.totalAndQrBillOnSameSite && !pdfFormatInfo.compactBill));
      docDefinition.content.push(await this.getCutLine());

      return {
        name: docName,
        pdf: pdfMake.createPdf(docDefinition)
      };
    } catch (error) {
      useToast().error(`PDF-Generierung fehlgeschlagen: ${Utils.getError(error)}`);
      console.error(error);
    }
  }

  private static standardText(text: any, marginTop: number = 0, marginBottom: number = 10) {
    return {
      text: text,
      fontSize: 9,
      margin: [0, marginTop, 0, marginBottom] // left, top, right, bottom
    }
  }

  private static getAddresses(company: Company, customer: Customer, marginTop: number, marginBetween: number) {
    return {
      columns: [
        {
          // Company Address
          width: '*',
          text: [
            company.name,
            company.address.street,
            `${company.address.zip} ${company.address.city}`,
            company.uid,
          ].map(val => this.lineBreak(val)).join(''),
          fontSize: 10,
          margin: [0, marginTop, 0, 30]
        },
        {
          width: marginBetween,
          text: ''
        },
        {
          // Customer Address
          width: '*',
          text: [
            customer.companyName,
            `${customer.lastname ? customer.title + '\n' + customer.firstname + ' ' + customer.lastname : ''}`,
            customer.address.street,
            `${customer.address.zip} ${customer.address.city}`
          ].map(val => this.lineBreak(val)).join(''),
          fontSize: 10,
          margin: [0, marginTop, 0, 30]
        }
      ]
    }
  }

  private static getTableTitle(title: string, marginTop: number, pageBreak: boolean, show: boolean = true): any {
    return !show ? {} : {
      text: title,
      fontSize: 11,
      margin: [0, marginTop, 0, 10],
      bold: true,
      ...(pageBreak && { pageBreak: 'before' })
    }
  }

  private static getWorkPositionTable(invoiceInfo: InvoiceInfo, marginBottom: number): any {
    const workerBody: any[][] = [[{text: 'Datum', bold: true}, {text: 'Arbeiten', bold: true}, {text: 'Anzahl', bold: true}, {text: 'Stundensatz', bold: true}, {text: 'Total', bold: true, alignment: 'right'}]]
    const groupSizes: number[] = [1];
    let currentGroupSize = 1;
    invoiceInfo.calculation.workReportPositions.forEach(position => {
      workerBody.push([DateConverter.convertToLocalDate(position.date), position.invoiceInscription, {}, {}, {}]);
      position.workers.forEach(worker => {
        workerBody.push([{}, worker.quantity + 'x ' + worker.name, `${worker.hoursWorked} ${Type.getImmutableUnit(Units.HOURS).abbreviation()}`, `${this.currency(worker.pricePerHour)}/${Type.getImmutableUnit(Units.HOURS).abbreviation()}`, {text: this.currency(worker.total), alignment: 'right'}]);
      });
      groupSizes.push(currentGroupSize + position.workers.length + 1);
      currentGroupSize += position.workers.length + 1;
    });
    workerBody.push([{text: 'Total', bold: true, colSpan: 4}, {}, {}, {}, {text: this.currency(invoiceInfo.calculation.totals.workTotal), bold: true, alignment: 'right'}])

    return invoiceInfo.calculation.workReportPositions.length == 0 ? {} : {
      fontSize: 9,
      table: {
        headerRows: 1,
        widths: [50, 270, 35, '*', '*'],
        body: workerBody
      },
      layout: {
        hLineWidth: function(i: number, node: any) {
          if (groupSizes.includes(i)) return 0.5;
          return 0;
        },
        vLineWidth: (i: number, node: any) => 0,
        paddingTop: (i: number, node: any) => 2,
        paddingBottom: (i: number, node: any) => 2,
      },
      margin: [0, 0, 0, marginBottom]
    }
  }

  private static getMaterialPositionTable(invoiceInfo: InvoiceInfo, marginBottom: number): any {
    return TenantSettings.isTransport()
      ? this.getTransportMaterialPositionTable(invoiceInfo, marginBottom)
      : this.getRegularMaterialPositionTable(invoiceInfo, marginBottom);
  }

  private static getRegularMaterialPositionTable(invoiceInfo: InvoiceInfo, marginBottom: number): any {
    const calculation = invoiceInfo.calculation;

    return calculation.materialReportPositions.length == 0 ? {} : {
      fontSize: 9,
      table: {
        headerRows: 1,
        widths: [50, 150, '*', '*', '*'],
        body: [
          [{text: 'Datum', bold: true}, {text: invoiceInfo.settings.materialTableTitle, bold: true}, {text: 'Anzahl', bold: true}, {text: 'Einheitspreis', bold: true}, {text: 'Total', bold: true, alignment: 'right'}],
          ...calculation.materialReportPositions.map(item => [DateConverter.convertToLocalDate(item.date), item.description ? item.description : (item.category ? item.category.invoiceInscription : ''), `${item.quantity} ${Type.getUnit(item.unitId).abbreviation()}`, `${this.currency(item.unitPrice)}/${Type.getUnit(item.unitId).abbreviation()}`, {text: this.currency(item.total), alignment: 'right'}]),
          [{ text: 'Total', colSpan: 3, bold:true }, {}, {}, {}, {text: this.currency(calculation.totals.materialTotal), bold: true, alignment: 'right'}]
        ]
      },
      layout: {
        hLineWidth: (i: number, node: any) => {
          if (i === 0 || i === node.table.body.length) return 0;
          return 0.5;
        },
        vLineWidth: (i: number, node: any) => 0,
        paddingTop: (i: number, node: any) => 2,
        paddingBottom: (i: number, node: any) => 2,
      },
      margin: [0, 0, 0, marginBottom]
    }
  }

  private static getTransportMaterialPositionTable(invoiceInfo: InvoiceInfo, marginBottom: number): any {
    const calculation = invoiceInfo.calculation;

    return calculation.materialReportPositions.length == 0 ? {} : {
      fontSize: 9,
      table: {
        headerRows: 1,
        widths: [50, 110, 110, '*', '*', '*'],
        body: [
          [{text: 'Datum', bold: true}, {text: Utils.getLoadingAndDropLocation(invoiceInfo.settings.materialTableTitle).load, bold: true}, {text: Utils.getLoadingAndDropLocation(invoiceInfo.settings.materialTableTitle).drop, bold: true}, {text: 'Anzahl', bold: true}, {text: 'Einheitspreis', bold: true}, {text: 'Total', bold: true, alignment: 'right'}],
          ...calculation.materialReportPositions.map(item => [DateConverter.convertToLocalDate(item.date), item.description ? Utils.getLoadingAndDropLocation(item.description).load : '', item.description ? Utils.getLoadingAndDropLocation(item.description).drop : '', `${item.quantity} ${Type.getUnit(item.unitId).abbreviation()}`, `${this.currency(item.unitPrice)}/${Type.getUnit(item.unitId).abbreviation()}`, {text: this.currency(item.total), alignment: 'right'}]),
          [{ text: 'Total', colSpan: 3, bold:true }, {}, {}, {}, {}, {text: this.currency(calculation.totals.materialTotal), bold: true, alignment: 'right'}]
        ]
      },
      layout: {
        hLineWidth: (i: number, node: any) => {
          if (i === 0 || i === node.table.body.length) return 0;
          return 0.5;
        },
        vLineWidth: (i: number, node: any) => 0,
        paddingTop: (i: number, node: any) => 2,
        paddingBottom: (i: number, node: any) => 2,
      },
      margin: [0, 0, 0, marginBottom]
    }
  }

  private static getTotalCostTable(invoiceInfo: InvoiceInfo): any {
    const calculation = invoiceInfo.calculation
    const totals = calculation.totals;
    const hasWorkPositions = calculation.workReportPositions.length > 0;
    const hasMaterialPositions = calculation.materialReportPositions.length > 0;
    const hasDiscounts = calculation.discounts.length > 0;

    const tableBody: any = []
    if (hasWorkPositions && (hasDiscounts || hasMaterialPositions)) tableBody.push([`Total ${invoiceInfo.settings.workTableTitle}`, {text: this.currency(totals.workTotal), alignment: 'right'}])
    if (hasMaterialPositions && (hasDiscounts || hasWorkPositions)) tableBody.push([`Total ${invoiceInfo.settings.materialTableTitle.split(';;;')[0]}`, {text: this.currency(totals.materialTotal), alignment: 'right'}])
    if (hasDiscounts && hasMaterialPositions && hasWorkPositions) tableBody.push(['Zwischentotal', {text: this.currency(totals.workTotal + totals.materialTotal), alignment: 'right'}]);

    calculation.discounts.forEach(item => {
      tableBody.push([
        `${Type.getDiscountType(item.discountTypeId).name}${Type.getDiscountType(item.discountTypeId).relative ? '   ' + (Type.getDiscountType(item.discountTypeId).deduction ? '-' : '+') + ' ' + item.amount + ' ' + Type.getImmutableUnit(Units.PERCENTAGE).abbreviation() : ''} ${item.expirationDate != 0 ? '(gültig bis ' + DateConverter.convertToLocalDate(item.expirationDate) + ')' : ''}`,
        {text: `${Type.getDiscountType(item.discountTypeId).deduction ? '-' : '+'} ${this.currency(item.total)}`, alignment: 'right'}
      ]);
    })

    if (!invoiceInfo.settings.pricesIncludingVat) tableBody.push([{ text: 'Total exklusive Mehrwertsteuer', bold:true }, {text: this.currency(totals.totalBeforeVat), bold: true, alignment: 'right'}]);
    if (!invoiceInfo.settings.pricesIncludingVat) tableBody.push([`Mehrwertsteuer (${invoiceInfo.company.vatRate} ${Type.getImmutableUnit(Units.PERCENTAGE).abbreviation()})`, {text: this.currency(totals.totalVat), alignment: 'right'}])
    tableBody.push([{ text: 'Total inklusive Mehrwertsteuer', bold:true }, {text: this.currency(totals.totalAfterVat), bold: true, alignment: 'right'}]);

    return {
      fontSize: 9,
      table: {
        headerRows: 0,
        widths: ['*', '*'],
        body: tableBody,
      },
        layout: {
          hLineWidth: function(i: number, node: any) {
            if (i === 0 || i === node.table.body.length) return 0;
            if (i === node.table.body.length - 1) return 1.5;
            return 0.5;
          },
          vLineWidth: (i: number, node: any) => 0,
          paddingTop: (i: number, node: any) => 2,
          paddingBottom: (i: number, node: any) => 2
        }
      }
  }

  private static getPaymentConditionsText(invoiceInfo: InvoiceInfo) {
    const skonto = invoiceInfo.subproject.skonto;
    const skontoExpiration = invoiceInfo.subproject.skontoExpiration;
    const skontoExpirationText = `bis zum ${skontoExpiration && skontoExpiration ? DateConverter.convertToLocalDate(skontoExpiration) : DateConverter.convertToLocalDate((new Date().getTime() / 1000) + invoiceInfo.settings.skontoRuntime * 24 * 60 * 60)}`
    const skontoText = skonto && skonto > 0 ? `\nBei Zahlung ${skontoExpirationText} werden ${skonto} % Skonto gewährt. ` : '';
    const skontoTotalText = skonto && skonto > 0 ? `Betrag bei Zahlung ${skontoExpirationText} = ${this.currency(invoiceInfo.calculation.totals.totalWithSkonto)}` : '';
    const paymentConditionsText = [
      `Rechnungsbetrag zahlbar innerhalb von ${invoiceInfo.settings.paymentDeadline} Tagen.`,
      skontoText,
      {text: skontoTotalText, bold: true}];
    return this.standardText(paymentConditionsText, 30, 0);
  }

  private static getQrBill(base64QrBill: string, pageBreakBefore: boolean) {
    return {
      svg: base64QrBill,
      width: 595,
      absolutePosition: { x: 5, y: 560 },
      ...((pageBreakBefore) && { pageBreak: 'before' })
    }
  }

  private static async getCutLine() {
    const cutLineImage = await this.convertToBase64(cutLinePng);
    return {
      image: cutLineImage,
      width: 595,
      absolutePosition: { x: 0, y: 555 }
    }
  }

  private static getLogo(base64Logo: string, height: number, marginTop: number) {
    return {
      image: `data:image/png;base64,${base64Logo}`,
      fit: [500, height],
      margin: [0, marginTop, 0, 0]
    }
  }

  private static currency(value: number): string {
    return `${Utils.formatCurrency(value)} ${Type.getImmutableUnit(Units.SWISS_FRANCS).abbreviation()}`
  }

  private static async convertToBase64(url: string) {
    return new Promise<string>((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function() {
        const reader = new FileReader();
        reader.onloadend = function() {
          resolve(reader.result as string);
        };
        reader.onerror = reject;
        reader.readAsDataURL(xhr.response);
      };
      xhr.onerror = reject;
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    });
  }

  private static lineBreak(value: string | undefined): string {
    if (!value || value == '') return '';
    return value + '\n';
  }


}