import { de } from '@formkit/i18n'


export enum ReportType {
    WORK = 'WORK',
    MATERIAL = 'MATERIAL'
}

export enum Units {
    SWISS_FRANCS = 'unit-swiss-francs',
    EUROS = 'unit-euros',
    DOLLARS = 'unit-dollars',
    PERCENTAGE = 'unit-percentage',
    HOURS = 'unit-hours',
    KILOMETER = 'unit-kilometer'
}

export interface BasicType {
    id: string,
    name: string
}

export interface PaymentType extends BasicType {
}

export interface DiscountType extends BasicType {
    standardAmount: number,
    relative: boolean,
    hidden: boolean,
    deduction: boolean,
}

export interface Status extends BasicType {
    priority: number,
    displayColor: string,
    locking: boolean
}

export interface CategoryType extends BasicType {
    reportType: ReportType,
    displayColor: string
}

export interface AbstractUnit extends BasicType {
    abbreviation: string,
    hidden: boolean
}

export interface AbstractImmutableUnit extends AbstractUnit {
    currency: boolean
}


export class Unit implements BasicType {
    public id: string;
    public name: string;
    public abbrev: string;
    public hidden: boolean;

    constructor(id: string, name: string, abbreviation: string, hidden: boolean) {
        this.id = id;
        this.name = name;
        this.abbrev = abbreviation;
        this.hidden = hidden;
    }

    public abbreviation(): string {
        return this.abbrev.replace(/\\u[\dA-F]{4}/gi, function (match) {
            return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
        });
    }
}

export class ImmutableUnit extends Unit {
    public isCurrency : boolean;

    constructor(id: string, name: string, abbreviation: string, currency: boolean) {
        super(id, name, abbreviation, false);
        this.isCurrency = currency;
    }
}

export class Type {

    private static paymentTypes = new Map<string, PaymentType>;
    private static discountTypes = new Map<string, DiscountType>;
    private static statuses = new Map<string, Status>;
    private static categoryTypes = new Map<string, CategoryType>;
    private static units = new Map<string, Unit>;
    private static immutableUnits = new Map<string, ImmutableUnit>;

    public static initValues(collection: TypesCollection) {
        if (!this.mapsAreEmpty()) return;
        this.paymentTypes = this.listToMap(collection.paymentTypes);
        this.discountTypes = this.listToMap(collection.discountTypes);
        this.statuses = this.listToMap(collection.statuses);
        this.categoryTypes = this.listToMap(collection.categoryTypes);
        this.units = this.listToMap(collection.units.map(unit => new Unit(unit.id, unit.name, unit.abbreviation, unit.hidden)));
        this.immutableUnits = this.listToMap(collection.immutableUnits.map(unit => new ImmutableUnit(unit.id, unit.name, unit.abbreviation, unit.currency)));
    }

    public static updateUnits(units: AbstractUnit[]) {
        this.units = this.listToMap(units.map(unit => new Unit(unit.id, unit.name, unit.abbreviation, unit.hidden)));
    }

    public static updateDiscountTypes(discountTypes: DiscountType[]) {
        this.discountTypes = this.listToMap(discountTypes);
    }

    public static getAllPaymentTypes() {
        return [...this.paymentTypes.values()];
    }

    public static getPaymentType(id: string): PaymentType {
        return this.getFromMap(this.paymentTypes, id);
    }

    public static getAllDiscountTypes() {
        return [...this.discountTypes.values()].filter(d => !d.hidden);
    }

    public static getAllDiscountTypesUnfiltered() {
        return [...this.discountTypes.values()];
    }

    public static getDiscountType(id: string): DiscountType {
        return this.getFromMap(this.discountTypes, id);
    }

    public static getAllStatuses() {
        return [...this.statuses.values()];
    }

    public static getStatus(id: string): Status {
        return this.getFromMap(this.statuses, id);
    }

    public static getAllCategoryTypes() {
        return [...this.categoryTypes.values()];
    }

    public static getCategoryType(id: string): CategoryType {
        return this.getFromMap(this.categoryTypes, id);
    }

    public static getAllUnits() {
        return [...this.units.values()].filter(u => !u.hidden);
    }

    public static getAllUnitsUnfiltered() {
        return [...this.units.values()];
    }

    public static getUnit(id: string): Unit {
        return this.getFromMap(this.units, id);
    }

    public static getAllImmutableUnits() {
        return [...this.immutableUnits.values()];
    }

    public static getImmutableUnit(id: Units): ImmutableUnit {
        return this.getFromMap(this.immutableUnits, id.valueOf());
    }

    private static getFromMap<T extends BasicType>(map: Map<string, T>, id: string): T {
        const element = map.get(id);
        if (!element) {
            throw new Error(`Type with id ${id} not found`);
        }
        return element;
    }

    private static listToMap<T extends BasicType>(list: T[]): Map<string, T> {
        const map = new Map<string, T>();
        list.forEach(element => map.set(element.id, element));
        return map;
    }

    private static isEmpty(map: Map<string, BasicType>): boolean {
        return map.size == 0;
    }

    private static mapsAreEmpty(): boolean {
        return    (this.isEmpty(this.paymentTypes)
                && this.isEmpty(this.discountTypes)
                && this.isEmpty(this.statuses)
                && this.isEmpty(this.categoryTypes)
                && this.isEmpty(this.units));
    }

}

export interface TypesCollection {
    paymentTypes: PaymentType[],
    discountTypes: DiscountType[],
    statuses: Status[],
    categoryTypes: CategoryType[],
    units: AbstractUnit[],
    immutableUnits: AbstractImmutableUnit[]
}