<template>
  <div>
    <div>
      <TheHeader v-if="route.path !== '/login' && route.path !== '/company/create'" @activate-menu="menuGetsActivated" />
      <TheBreadcrumb v-if="company" />
      <main class="sm:mx-outerFrameBorder flex justify-center" :class="route.path == '/' || route.path == '/dashboard' || route.path.startsWith('/project/') ? 'sm:mb-outerFrameBorder' : 'mb-outerFrameBorder'">
        <RouterView class="flex-1"/>
      </main>
    </div>
      <teleport to="body">
        <transition name="menu">
          <TheHamburgerMenu v-if="showMenu" @deactivate-menu="menuGetsDeactivated"/>
        </transition>
      </teleport>
  </div>
</template>

<script setup lang="ts">
import { RouterView, useRoute, useRouter } from 'vue-router'
import TheHeader from './components/layout/TheHeader.vue'
import TheHamburgerMenu from './components/layout/TheHamburgerMenu.vue'
import TheBreadcrumb from '../src/components/layout/TheBreadcrumb.vue'
import { ref } from 'vue'
import { API } from '@/client/axios'
import { routeNames } from '@/router'
import { useCompanyStore } from './stores/company'
import { Type, type TypesCollection } from '@/model/Type'
import { TenantCompany } from '@/stores/TenantCompany'
import { ObjectStorage, StorageKey } from '@/service/ObjectStorage'
import { TenantSettings } from '@/stores/TenantSettings'

// Show/Hide Hamburger Menu
const showMenu = ref(false);

const companyStore = useCompanyStore();
const company = companyStore.getCompany;
const route = useRoute();

const menuGetsActivated = () => {
  document.body.classList.add('overflow-hidden');
  showMenu.value = true;
}

const menuGetsDeactivated = () => {
  document.body.classList.remove('overflow-hidden');
  showMenu.value = false;
}

// Init Application
async function initApplication() {

  const router = useRouter();
  API.init();

  if (!loadTypes() || !TenantCompany.loadCompanyFromStore() || !TenantSettings.loadSettingsFromStore()) {
    await router.push({name: routeNames.INIT});
  }
}

function loadTypes() {
  const types = ObjectStorage.get<TypesCollection>(StorageKey.TYPES)
  if (!types) return false;
  Type.initValues(types);
  return true;
}

initApplication();

</script>

<style scoped>

</style>
