import { useToast } from 'vue-toast-notification'
import { API, PathSegment } from '@/client/axios'
import type { Page } from '@/model/Page'
import { ResponseKey } from '@/model/ResponseWrapper'
import { Utils } from '@/client/utils'
import type { MaterialReportPositionResponse } from '@/model/MaterialReportPosition'

const $toast = useToast();

export async function getMaterialReportPage(subprojectId: string, pageSize: number, offset: number) {
  try {
    const response = await API.getWithParameters<Page<MaterialReportPositionResponse>>(
      PathSegment.SUBPROJECTS,
      subprojectId,
      PathSegment.MATERIAL_REPORT_POSITIONS,
      new Map<string, string>([['pageSize', `${pageSize}`], ['offset', `${offset}`]])
    );
    if (response.key == ResponseKey.OK) return response.data;
    $toast.error(`Materialrapport konnte nicht geladen werden: ${response.message}`);
  } catch (error) {
    $toast.error(`Materialrapport konnte nicht geladen werden: ${Utils.getError(error)}`);
  }
}