<template>
  <div class="container-1 mt-outerFrameBorder mb-outerFrameBorder">
    <svg class="spinner" viewBox="0 0 50 50">
      <circle
        class="path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        stroke-width="5"
      ></circle>
    </svg>
  </div>
</template>

<style scoped>
.container-1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  animation: spin 2s linear infinite;
  width: 50px;
  height: 50px;
}

.spinner .path {
  stroke: rgb(54, 90, 220);
  stroke-linecap: round;
  animation: spinner-path 1.5s ease-in-out infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinner-path {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
</style>
