export class DateConverter {
  static convertToLocalDateTime(timestamp: number): string {
    const date: Date = new Date(timestamp * 1000);
    const humanReadableDate: string = date.toLocaleDateString();
    const humanReadableTime: string = date.toLocaleTimeString();
    return `${humanReadableDate} ${humanReadableTime}`;
  }

  static convertToLocalDate(timestamp: number): string {
    const date: Date = new Date(timestamp * 1000);
    return `${date.toLocaleDateString()}`;
  }
  static convertToLocalDateISO(timestamp: number): string {
    const date: Date = new Date(timestamp * 1000);
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  static convertToUnixTimestamp(localDateString: string): number {
    const date = new Date(localDateString);
    return Math.floor(date.getTime() / 1000);
  }

  static getCurrentLocalDateISO(): string {
    const currentDate = new Date();
    const year = currentDate.getFullYear().toString();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
    const day = currentDate.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  static getCurrentLocalDate(): string {
    const currentDate = new Date();
    const year = currentDate.getFullYear().toString();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
    const day = currentDate.getDate().toString().padStart(2, '0');

    return `${day}.${month}.${year}`;
  }
}