import type Category from '@/model/Category'

export class InvoiceInscriptionBuilder {

  public static readonly COMMA = ",";
  public static readonly AND = "und";
  public static readonly DOT = ".";

  static build(sentences: Category[][]): string {
    if (sentences.length == 0) return '';

    let finalInscription = '';
    for (const sentence of sentences) {
      finalInscription += `${this.buildSentence(sentence)} `;
    }
    return finalInscription;
  }

  static buildSentence(sentence: Category[]): string {
    if (sentence.length == 0) return '';

    let finalSentence = '';
    for (let i = 0; i < sentence.length; i++) {
      finalSentence += this.textBasedOfSurroundingWords(i, sentence);
    }
    return finalSentence + this.DOT;
  }

  private static textBasedOfSurroundingWords(index: number, sentence: Category[]): string {
    const word = index == 0 ? this.capitalizeFirstLetter(sentence[index].invoiceInscription) : sentence[index].invoiceInscription;
    if (index > 1 && sentence[index - 1].categoryTypeId != 'category-type-work' && sentence[index].categoryTypeId == 'category-type-work') {
      return `${this.COMMA} ${word}`;
    } else {
      switch (this.previousAndNextCategoryIdentical(index, sentence)) {
        case Position.PREVIOUS: return ` ${this.AND} ${word}`;
        case Position.BOTH: return `${this.COMMA} ${word}`;
        default: return `${index == 0 ? '' : ' '}${word}`;
      }
    }
  }

  private static previousAndNextCategoryIdentical(index: number, sentence: Category[]) {
    if (sentence.length < 2) return Position.NONE;

    if (index == 0) {
      if (sentence[index].categoryTypeId == sentence[index + 1].categoryTypeId) return Position.NEXT;
      else return Position.NONE;
    }

    if (index == sentence.length - 1) {
      if (sentence[index].categoryTypeId == sentence[index - 1].categoryTypeId) return Position.PREVIOUS;
      else return Position.NONE;
    }

    if (sentence.length < 3) return Position.NONE;

    if (sentence[index].categoryTypeId == sentence[index + 1].categoryTypeId) {
      if (sentence[index].categoryTypeId == sentence[index - 1].categoryTypeId) return Position.BOTH;
      else return Position.NEXT;
    }
    if (sentence[index].categoryTypeId == sentence[index - 1].categoryTypeId) return Position.PREVIOUS;

    return Position.NONE;
  }

  private static capitalizeFirstLetter(str: string): string {
    if (!str) return str; // returns the original string if it's empty or null
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

}

enum Position {
  NONE = 0,
  PREVIOUS = 1,
  NEXT = 2,
  BOTH = 3
}