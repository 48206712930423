<template>
  <base-site :title="title">
    <base-container>
      <base-heading v-if="KeycloakService.isAdmin() && !editMode">
        <!-- <base-icon class="text-[calc(theme(fontSize.iconSize)+0.2rem)] mr-3">add_business</base-icon> -->
        Zum Starten, erfassen Sie Ihre Firma
      </base-heading>
      <FormKit v-if="KeycloakService.isAdmin()" type="form" :submit-label="submitLabel" @submit="onSubmit" :config="{ validationVisibility: 'blur' }">
        <base-heading type="h2" class="border-b border-primary pb-4 mt-7 !mb-10 text-primary">Grunddaten</base-heading>
        <div class="md:flex md:space-x-betweenElements">
          <div class="flex-1">
            <FormKit type="text" name="name" id="name" label="Name der Firma*" validation="required" v-model="company.name" />
            <FormKit type="text" name="uid" id="uid" label="UID (Unternehmens-Identifikationsnummer)" help="z.B. CHE-123.456.789" placeholder="CHE-XXX.XXX.XXX" :validation="[['matches', /^(CHE-)?\d{3}\.\d{3}\.\d{3}$/]]" :validation-messages="{matches: 'Format: CHE-XXX.XXX.XXX'}" v-model="company.uid" />
            <FormKit type="tel" name="landlineNumber" id="landlineNumber" label="Festnetz" help="z.B. 032 123 45 68" :validation="[['matches', /^(0\d{2}\s\d{3}\s\d{2}\s\d{2})|(\+\d{2}\s\d{2}\s\d{3}\s\d{2}\s\d{2})$/]]" :validation-messages="{matches: 'Format: 0XX XXX XX XX oder +XX XX XXX XX XX'}" v-model="company.landlineNumber" />
            <FormKit type="tel" name="mobilePhoneNumber" id="mobilePhoneNumber" label="Mobile" help="z.B. 078 123 45 68" :validation="[['matches', /^(0\d{2}\s\d{3}\s\d{2}\s\d{2})|(\+\d{2}\s\d{2}\s\d{3}\s\d{2}\s\d{2})$/]]" :validation-messages="{matches: 'Format: 0XX XXX XX XX oder +XX XX XXX XX XX'}" v-model="company.mobilePhoneNumber" />
            <FormKit type="email" name="email" id="email" label="E-Mail" placeholder="beispiel@domain.ch" :validation="[['matches', /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/]]" :validation-messages="{matches: 'Keine gültige E-mail-Adresse'}" v-model="company.email" />
            <FormKit type="number" number step="any" inputmode="decimal" name="vatRate" id="vatRate" label="Mehrwertsteuersatz in %*" validation="required" v-model="company.vatRate" />
          </div>
          <div class="flex-1">
            <base-container type="inner" class="base-container">
              <base-heading type="h2" class="text-black">Firmenadresse</base-heading>
              <FormKit type="group" name="address" id="address">
                <FormKit type="text" name="street" id="street" label="Strasse und Nr.*" validation="required" v-model="company.address.street" />
                <div class="flex flex-col @sm:flex-row @sm:space-x-betweenElements">
                  <FormKit type="text" name="zip" id="zip" label="Postleitzahl*" :classes="{outer: 'flex-1'}" validation="required" v-model="company.address.zip" />
                  <FormKit type="text" name="city" id="city" label="Ort*" :classes="{outer: 'flex-1'}" validation="required" v-model="company.address.city" />
                </div>
                <div class="flex flex-col @sm:flex-row @sm:space-x-betweenElements">
                  <FormKit type="text" name="state" id="state" label="Kanton" :classes="{outer: 'flex-1'}" v-model="company.address.state" />
                </div>
              </FormKit>
            </base-container>
            <FormKit type="file" name="logo" id="logo" label="Firmenlogo" accept=".png,.jpg,.svg" multiple="false" />
            <div v-if="logoUrl">
              <p class="text-bodyMediumBold mt-betweenElements mb-4">Aktuelles Logo</p>
              <img :src="logoUrl" alt="Kein Logo vorhanden" class="h-14 w-auto" />
            </div>
            <!--<FormKit type="file" name="signature" id="signature" label="Unterschrift" accept=".png,.jpg,.svg" multiple="false" :classes="{outer: 'mb-0'}" /> -->
          </div>
        </div>
        <base-heading type="h2" class="border-b border-primary pb-4 mt-11 !mb-10 text-primary">Bankverbindung</base-heading>
        <div>
          <FormKit type="group" name="bankDetails" id="bankDetails">
            <div class="md:flex md:space-x-betweenElements">
              <div class="flex-1">
                <FormKit type="text" name="bankName" id="bankName" label="Name der Bank" v-model="company.bankDetails.bankName" />
                <FormKit type="text" name="iban" id="iban" label="IBAN*" help="z.B. CH39 0070 0115 6518 4917 3" placeholder="CHXX XXXX XXXX XXXX XXXX X" :validation="[['required'], ['matches', /^CH\d{2}\s\d{4}\s\d{4}\s\d{4}\s\d{4}\s\d{1}$/]]" :validation-messages="{matches: 'Format: CHXX XXXX XXXX XXXX XXXX X'}" v-model="company.bankDetails.iban" />
              </div>
              <div class="flex-1">
                <base-container type="inner" class="base-container">
                  <base-heading type="h2" class="text-black">Bankadresse</base-heading>
                  <FormKit type="group" name="address" id="address">
                    <FormKit type="text" name="street" id="street" label="Strasse" v-model="bankAddress.street" />
                    <div class="flex flex-col @sm:flex-row @sm:space-x-betweenElements">
                      <FormKit type="text" name="zip" id="zip" label="PLZ" :classes="{outer: 'flex-1'}" v-model="bankAddress.zip" />
                      <FormKit type="text" name="city" id="city" label="Ort" :classes="{outer: 'flex-1'}" v-model="bankAddress.city" />
                    </div>
                    <div class="flex flex-col @sm:flex-row @sm:space-x-betweenElements">
                      <FormKit type="text" name="state" id="state" label="Kanton" :classes="{outer: 'flex-1 mb-0'}" v-model="bankAddress.state" />
                    </div>
                  </FormKit>
                </base-container>
              </div>
            </div>
          </FormKit>
        </div>
      </FormKit>
      <div v-else>
        <p class="text-bodyMediumBold text-grey-dark mb-11 mt-11">Ihre Firma wurde noch nicht erstellt. Bitte melden Sie sich als Administrator an.</p>
        <base-button @click="KeycloakService.logout()">Logout</base-button>
      </div>
    </base-container>
  </base-site>
</template>

<script setup lang="ts">
import type Company from '@/model/Company'
import { API, PathSegment } from '@/client/axios'
import { useRoute, useRouter } from 'vue-router'
import { routeNames } from '@/router'
import BaseSite from '@/components/base/BaseSite.vue'
import { TenantCompany } from '@/stores/TenantCompany'
import { onMounted, ref } from 'vue'
import type Address from '@/model/Address'
import { KeycloakService } from '@/service/keycloakService'
import BaseButton from '@/components/base/BaseButton.vue'
import { ResponseKey } from '@/model/ResponseWrapper'
import { useToast } from 'vue-toast-notification'
import { ObjectStorage, StorageKey } from '@/service/ObjectStorage'

const route = useRoute()
const router = useRouter()
const editMode = ref(true);
const title = ref('Firma');
const submitLabel = ref('Speicher');
const company = ref<Company>(emptyCompany());
const bankAddress = ref<Address>(emptyAddress());
const logoUrl = ref('');

let dataSaveSuccess = false;
let logoSaveSuccess = false;

const $toast = useToast();

async function onSubmit(fields: any) {
  if (editMode.value) await updateCompany(company.value);
  else await createCompany(fields);

  if (fields.logo[0]) {
    await postLogo(fields.logo[0].file);
  } else logoSaveSuccess = true;

  if (!dataSaveSuccess || !logoSaveSuccess) {
    await loadCompany();
    return;
  }

  if (editMode.value) await router.push({name: routeNames.DASHBOARD});
  else await router.push({name: routeNames.CUSTOMERS});
}

async function createCompany(company: Company) {
  console.log(company);
  company = cleanBankAddressAndUid(company);
  const response = await API.createDataObject<Company>(PathSegment.COMPANIES, company, 'Firma');
  if (response) {
    ObjectStorage.set(StorageKey.COMPANY, response);
    TenantCompany.setCompany(response);
    dataSaveSuccess = true;
  }
}

async function updateCompany(company: Company) {
  console.log(company);
  company = cleanBankAddressAndUid(company);
  const response = await API.updateDataObject<Company>(PathSegment.COMPANIES, company, 'Firma');
  if (!response) return;
  TenantCompany.setCompany(company);
  dataSaveSuccess = true;
}

async function postLogo(file: File) {
  console.log(file);
  const formData = new FormData();
  formData.append('logo', file);
  const response = await API.postMultiPart<void>(`${PathSegment.COMPANIES}/logo`, formData);
  if (response.key == ResponseKey.CREATED) {
    logoSaveSuccess = true;
  } else $toast.error(response.message);
}

async function loadCompany() {
  const response = await API.getDataObject<Company>(PathSegment.COMPANIES, '', 'Firma');
  if (!response) return;
  formatCompany(response);
  company.value = response;
  if (company.value.bankDetails.address) bankAddress.value = company.value.bankDetails.address;
}

async function loadCompanyLogo() {
  const response = await API.getImage(PathSegment.COMPANIES_LOGO);
  if (!response) {
    console.error('Getting Image Failed: !response');
    return;
  }
  logoUrl.value = URL.createObjectURL(response);
}

function formatCompany(company: Company) {
  if (company.landlineNumber) company.landlineNumber = formatPhoneNumber(company.landlineNumber);
  if (company.mobilePhoneNumber) company.mobilePhoneNumber = formatPhoneNumber(company.mobilePhoneNumber);
  company.bankDetails.iban = formatIBAN(company.bankDetails.iban);
}

function formatPhoneNumber(phoneNumber: string) {
  let cleanedNumber = phoneNumber.replace(/[^\d+]/g, '');

  const localPattern = /^(\d{3})(\d{3})(\d{2})(\d{2})$/;
  const mobilePattern = /^(\+41)(\d{2})(\d{3})(\d{2})(\d{2})$/;

  if (cleanedNumber.startsWith('+')) {
    return cleanedNumber.replace(mobilePattern, '$1 $2 $3 $4 $5');
  } else {
    return cleanedNumber.replace(localPattern, '$1 $2 $3 $4');
  }
}

function formatIBAN(iban: string): string {
  let cleanedIban = iban.replace(/\W/g, '');

  let formattedIban = '';
  for (let i = 0; i < cleanedIban.length; i += 4) {
    formattedIban += cleanedIban.substring(i, i + 4) + ' ';
  }

  return formattedIban.trim();
}

function cleanBankAddressAndUid(company: Company) {
  company.bankDetails.address = (!bankAddress.value.street && !bankAddress.value.zip && !bankAddress.value.city)
    ? null
    : bankAddress.value;
  company.uid = !company.uid ? undefined : company.uid;
  return company;
}

function emptyCompany(): Company {
  return  {
    id: '',
    name: '',
    address: {
      id: '',
      street: '',
      city: '',
      zip: '',
      state: '',
      country: '',
      createdAt: 0,
      updatedAt: 0
    },
    uid: '',
    mobilePhoneNumber: '',
    landlineNumber: '',
    email: '',
    logoPath: '',
    bankDetails: {
      id: '',
      iban: '',
      address: {
        id: '',
        street: '',
        city: '',
        zip: '',
        state: '',
        country: '',
        createdAt: 0,
        updatedAt: 0
      },
      accountNumber: '',
      bankName: '',
      createdAt: 0,
      updatedAt: 0
    },
    vatRate: 8.1,
    signatureImagePath: '',
    createdAt: 0,
    updatedAt: 0
  }
}

function emptyAddress(): Address {
  return {
      id: '',
      street: '',
      city: '',
      zip: '',
      state: '',
      country: '',
      createdAt: 0,
      updatedAt: 0
  }
}

onMounted(() => {
  if (route.path == '/company/edit') {
    title.value = 'Firma bearbeiten';
    submitLabel.value = 'Speichern';
    loadCompany();
    loadCompanyLogo();
  } else {
    editMode.value = false;
    title.value = 'Firma erstellen';
    submitLabel.value = 'Erfassen';
  }
})

</script>

<style scoped>
.base-container {
  @apply mb-betweenElements @container rounded-sm;
}
</style>

