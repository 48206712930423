<template>
  <base-site title="Einstellungen">

    <spinner v-if="loading" />

    <template v-else>
      <base-container  class="mb-betweenElements">
        <base-heading>
          <div class="title-icon-with-background">
            <base-icon>settings</base-icon>
          </div>
          Allgemein
        </base-heading>
        <FormKit type="radio" :options="priceIncludingVatOptions" v-model="pricesIncludingVat" class="mb-betweenElements" />
      </base-container>

      <base-container class="mb-betweenElements">
        <base-heading>
          <div class="title-icon-with-background">
            <base-icon>description</base-icon>
          </div>
          Rechnung
        </base-heading>
        <base-heading type="h2" class="border-b border-primary pb-4 mt-11 !mb-10 text-primary">Formatierung</base-heading>
        <FormKit type="number" number inputmode="decimal" label="Abstand Seite oben bis Logo*" v-model="distanceTopLogo" />
        <FormKit type="number" number inputmode="decimal" label="Logo Höhe*" v-model="logoHeight" />
        <FormKit type="number" number inputmode="decimal" label="Abstand Logo bis Adressen*" v-model="distanceLogoAddresses" />
        <FormKit type="number" number inputmode="decimal" label="Abstand zwischen Addressen*" v-model="distanceBetweenAddresses" />

        <base-heading type="h2" class="border-b border-primary pb-4 mt-11 !mb-10 text-primary">Inhalt</base-heading>
        <FormKit type="text" label="Rechnungstitel*" v-model="invoiceTitle"/>
        <div class="border border-grey-mid rounded p-5 mb-betweenElements">
          <FormKit type="textarea" label="Einleitungstext" v-model="invoiceHeader" />
          <button class="flex flex-row" @click="keysForHeaderOpen = !keysForHeaderOpen">
            <base-icon class="mr-betweenElements cursor-pointer hover:text-primary">{{keysForHeaderOpen ? 'expand_less' : 'expand_more'}}</base-icon>
            <div>Schlüssel für den Einleitungstext</div>
          </button>
          <InvoiceKeysTable v-if="keysForHeaderOpen" class="mt-betweenElements" :on-click="onAddKeyToHeader" />
        </div>
        <FormKit type="text" label="Tabellentitle Arbeitsrapport*" v-model="workTableTitle"/>
        <FormKit type="text" label="Tabellentitle Materialrapport*" v-model="materialTableTitle"/>
        <FormKit type="number" number inputmode="decimal" label="Standard Zahlungsfrist in Tagen*" v-model="paymentDeadline" />
        <FormKit type="number" number inputmode="decimal" label="Standard Skonto-Frist in Tagen*" v-model="skontoRuntime" />
        <div class="border border-grey-mid rounded p-5 mb-betweenElements">
          <FormKit type="textarea" label="Abschlusstext" v-model="invoiceFooter" />
          <button class="flex flex-row" @click="keysForFooterOpen = !keysForFooterOpen">
            <base-icon class="mr-betweenElements cursor-pointer hover:text-primary">{{keysForFooterOpen ? 'expand_less' : 'expand_more'}}</base-icon>
            Schlüssel für den Abschlusstext
          </button>
          <InvoiceKeysTable v-if="keysForFooterOpen" class="mt-betweenElements" :on-click="onAddKeyToFooter" />
        </div>
      </base-container>

      <base-container  class="mb-betweenElements">
        <base-heading>
          <div class="title-icon-with-background">
            <base-icon>assistant</base-icon>
          </div>
          Sprache zu Text
        </base-heading>
        <FormKit type="select" label="Übersetzungssprache*" :options="aiLanguageOptions" v-model="aiLanguage" />
        <FormKit type="text" label="Zusätzliche Infos zur Übersetzung (Prompt)" v-model="aiPrompt"/>
      </base-container>

      <div class="flex md:flex-row flex-col">
        <base-button class="md:mr-auto md:mb-0 md:mx-0 mx-betweenElements md:w-buttonXLarge" @click="updateSettings">
          <spinner-small v-if="requesting" />
          <div v-else>Speichern</div>
        </base-button>
        <base-button class="md:ml-auto md:mx-0 mx-betweenElements mt-8 md:mt-0 md:w-buttonXLarge" look="secondary-light" @click="onCancel">
          Abbrechen
        </base-button>
      </div>
<!--      <base-button look="primary" class="md:mx-0 mx-betweenElements md:w-buttonXLarge" @click="updateSettings">-->
<!--        <spinner-small v-if="requesting" />-->
<!--        <div v-else>Speichern</div>-->
<!--      </base-button>-->
    </template>

  </base-site>
</template>

<script setup lang="ts">

import BaseSite from '@/components/base/BaseSite.vue'
import BaseContainer from '@/components/base/BaseContainer.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import { onMounted, ref } from 'vue'
import Spinner from '@/components/base/Spinner.vue'
import SpinnerSmall from '@/components/base/SpinnerSmall.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import { API, PathSegment } from '@/client/axios'
import type Settings from '@/model/Settings'
import router, { routeNames } from '@/router'
import InvoiceKeysTable from '@/components/settings/InvoiceKeysTable.vue'
import { TenantSettings } from '@/stores/TenantSettings'

const loading = ref(true);
const requesting = ref(false);
const priceIncludingVatOptions = [
  {label: 'Preise exklusive Mehrwertssteuer', value: false},
  {label: 'Preise inklusive Mehrwertssteuer', value: true}];
const aiLanguageOptions = [{label: 'Deutsch', value: 'de'}, {label: 'Francais', value: 'fr'}, {label: 'English', value: 'en'}];

const pricesIncludingVat = ref(false);
const paymentDeadline = ref(10);
const skontoRuntime = ref(7);
const distanceTopLogo = ref(0);
const logoHeight = ref(45);
const distanceLogoAddresses = ref(30);
const distanceBetweenAddresses = ref(50);
const invoiceTitle = ref('Rechnung');
const invoiceHeader = ref('');
const workTableTitle = ref('Arbeiten');
const materialTableTitle = ref('Material');
const invoiceFooter = ref('');
const aiLanguage = ref('de');
const aiPrompt = ref('Arbeitsrapport');

const keysForHeaderOpen = ref(false);
const keysForFooterOpen = ref(false);

async function loadSettings() {
  loading.value = true;
  const response = await API.getDataObject<Settings>(PathSegment.SETTINGS, '', 'Einstellungen');
  if (!response) return;

  pricesIncludingVat.value = response.pricesIncludingVat;
  paymentDeadline.value = response.paymentDeadline
  skontoRuntime.value = response.skontoRuntime;
  distanceTopLogo.value = response.distanceTopLogo;
  logoHeight.value = response.logoHeight;
  distanceLogoAddresses.value = response.distanceLogoAddress;
  distanceBetweenAddresses.value = response.distanceBetweenAddresses;
  invoiceTitle.value = response.invoiceTitle;
  invoiceHeader.value = response.invoiceHeader ?? '';
  workTableTitle.value = response.invoiceWorkTableTitle;
  materialTableTitle.value = response.invoiceMaterialTableTitle;
  invoiceFooter.value = response.invoiceFooter ?? '';
  aiLanguage.value = response.aiLanguage;
  aiPrompt.value = response.aiPrompt;

  loading.value = false;
}

async function updateSettings() {
  requesting.value = true;
  const body: Settings = {
    tenant: '',
    pricesIncludingVat: pricesIncludingVat.value,
    paymentDeadline: paymentDeadline.value,
    skontoRuntime: skontoRuntime.value,
    distanceTopLogo: distanceTopLogo.value,
    logoHeight: logoHeight.value,
    distanceLogoAddress: distanceLogoAddresses.value,
    distanceBetweenAddresses: distanceBetweenAddresses.value,
    invoiceTitle: invoiceTitle.value,
    invoiceHeader: invoiceHeader.value == '' ? undefined : invoiceHeader.value,
    invoiceWorkTableTitle: workTableTitle.value,
    invoiceMaterialTableTitle: materialTableTitle.value,
    invoiceFooter: invoiceFooter.value == '' ? undefined : invoiceFooter.value,
    aiLanguage: aiLanguage.value,
    aiPrompt: aiPrompt.value,
    updatedAt: 0,
    useCase: TenantSettings.get.useCase
  }
  const response = await API.updateDataObject<Settings>(PathSegment.SETTINGS, body, 'Einstellungen');
  if (response) {
    TenantSettings.setSettings(response);
    router.go(-1);
  }
  requesting.value = false;
}

function onCancel() {
  router.go(-1);
}

function onAddKeyToHeader(key: string) {
  invoiceHeader.value += invoiceHeader.value[invoiceHeader.value.length - 1] == ' ' ? key : ` ${key}`;
}

function onAddKeyToFooter(key: string) {
  invoiceFooter.value += invoiceFooter.value[invoiceFooter.value.length - 1] == ' ' ? key : ` ${key}`;
}

onMounted(() => {
  loadSettings()
})

</script>

<style scoped>

</style>