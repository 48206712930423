<template>
  <div>
    <div class="flex items-center cursor-pointer">
      <slot></slot>
      <base-icon>arrow_drop_down</base-icon>
    </div>
    <div>
      <slot name="dropdown-menu"></slot>
    </div>
  </div>
</template>

<script setup lang="ts">

</script>
