<template>

  <base-site :title="project?.name ?? ''"
             :subtitle-above="TenantSettings.isProjectOriented()
                ? (project?.customer ? Utils.getCustomerTitle(project.customer) : undefined)
                : undefined"
             :subtitle-below="TenantSettings.isCustomerOriented() ? (project?.customer ?Utils.getCustomerOneLineAddress(project.customer) : undefined) : undefined"
             :show-button="KeycloakService.isManager()" button-icon="edit"
             @subtitle-above-action="router.push({name: routeNames.CUSTOMER, params: {id: project?.customer.id}})"
             @button-action="TenantSettings.isProjectOriented() ? onEditProject() : onEditCustomer()">

    <div v-if="selectedSubproject" ref="subprojectListContainer" class="flex justify-between pb-4 md:ml-0 ml-outerFrameBorder md:mr-0 mr-outerFrameBorder overflow-x-auto">
      <div class="flex w-full">
        <div v-for="(subproject, index) in subprojects" :key="index" class="flex-shrink-0 md:max-w-full max-w-20">
          <div :class="subproject.id == selectedSubproject.id ? 'selected-md' : 'selectable-md'"
               class="mr-4 h-full whitespace-nowrap overflow-hidden text-ellipsis min-w-0" @click="onChangeSubproject(subproject)">
            {{subproject.name}}
          </div>
        </div>
        <div v-if="KeycloakService.isManager()" class="selectable-md h-full text-grey-dark hover:text-primary" @click="onAddSubproject"><base-icon>add</base-icon></div>
      </div>
    </div>

    <div class="flex-col sm:bg-white sm:border sm:border-grey-mid sm:rounded-md px-0 py-0 sm:py-outerFrameBorder sm:px-outerFrameBorder mb-betweenElements">

      <spinner v-if="subprojectsLoading" />

      <div v-else-if="subprojects && subprojects.length == 0" class="flex flex-col items-center justify-center text-center h-full">
        <p class="text-bodyMediumBold align-middle text-grey-dark m-11">Keine Rechungen vorhanden</p>
        <base-button v-if="KeycloakService.isManager()" @click="onAddSubproject()">
          Neue Rechnung erstellen
        </base-button>
      </div>

      <div v-else-if="subprojects && selectedSubproject">

        <base-container class="mb-betweenElements">
          <div class="flex flex-row">
            <div class="flex flex-col">
              <div class="flex flex-row items-center">
                <p class="text-bodyMediumBold mb-2" :style="{ color: selectedSubproject.statusId ? Type.getStatus(selectedSubproject.statusId).displayColor : '#ffffff' }">
                  {{ selectedSubproject && selectedSubproject.statusId ? Type.getStatus(selectedSubproject!.statusId).name : '' }}
                </p>
                <base-icon v-if="Type.getStatus(selectedSubproject?.statusId).locking"
                           class="ml-4 mb-2" size="text-iconSizeSmall" :style="{ color: selectedSubproject.statusId ? Type.getStatus(selectedSubproject.statusId).displayColor : '#ffffff' }">lock</base-icon>
              </div>
              <p class="text-bodyMediumBold">
                {{  Utils.formatCurrency(selectedSubproject?.currentCosts) }}
                {{ Type.getImmutableUnit(Units.SWISS_FRANCS).abbreviation() }}
                {{ selectedSubproject?.costLimit > 0 ? `/ ${Utils.formatCurrency(selectedSubproject?.costLimit)} ${Type.getImmutableUnit(Units.SWISS_FRANCS).abbreviation()}` : '' }}
                {{ subprojectCostLimitPercentage() }}
              </p>
              <p>Referenznummer: {{selectedSubproject?.referenceNumber}}</p>
            </div>
            <base-button v-if="KeycloakService.isManager()" class="ml-auto mt-auto mb-auto rounded-lg" type="icon" look="secondary" @click="onEditSubproject()">
              <base-icon>edit</base-icon>
            </base-button>
          </div>
        </base-container>

        <div class="flex flex-col space-y-betweenElements">
          <work-report-card v-if="TenantSettings.isConstruction()"
                            id="workReport" class="flex-1 offset-scroll-into-view"
                            :subproject-id="selectedSubproject?.id"
                            :project-id="project?.id ?? ''"
                            :locked="Type.getStatus(selectedSubproject?.statusId).locking ?? false">
          </work-report-card>

          <transport-report-card v-if="TenantSettings.isTransport()" id="materialReport" class="flex-1 offset-scroll-into-view"
                                :subproject-id="selectedSubproject?.id"
                                :project-id="project?.id ?? ''"
                                :locked="Type.getStatus(selectedSubproject?.statusId).locking ?? false" />
          <material-report-card v-else id="materialReport" class="flex-1 offset-scroll-into-view"
                                :subproject-id="selectedSubproject?.id"
                                :project-id="project?.id ?? ''"
                                :locked="Type.getStatus(selectedSubproject?.statusId).locking ?? false" />

          <discount-card id="discounts" v-if="KeycloakService.isManager()"
                         :discounts="discounts ?? []"
                         :loading="discountsLoading"
                         :subproject-id="selectedSubproject?.id"
                         :locked="Type.getStatus(selectedSubproject?.statusId).locking ?? false"/>

        </div>

      </div>

    </div>

    <base-button v-if="KeycloakService.isManager() && !subprojectsLoading && selectedSubproject" look="primary" class="md:mr-auto md:ml-0 mx-betweenElements sm:mb-0 mb-betweenElements w-max md:w-auto" @click="onGeneratePdf">
      Rechungs PDF generieren
    </base-button>

    <generate-invoice-modal ref="pdfModal" :subproject="selectedSubproject" @on-close="onModalClose" />

    <base-container v-if="!modalOpen && selectedSubproject && !TenantSettings.isTransport()" class="sm:hidden !bg-grey-mid sticky rounded-t-md bottom-0 flex items-center justify-between h-overviewNav !py-7">
      <base-button type="icon" look="secondary" class="overview-nav-btn" @click="scrollTo('workReport')">
        <base-icon class="text-[calc(theme(fontSize.iconSize)+0.25rem)]">engineering</base-icon>
      </base-button>
      <base-button type="icon" look="secondary" class="overview-nav-btn" @click="scrollTo('materialReport')">
        <base-icon>handyman</base-icon>
      </base-button>
      <base-button type="icon" look="secondary" class="overview-nav-btn" @click="scrollTo('discounts')">
        <base-icon>percent</base-icon>
      </base-button>
    </base-container>

  </base-site>

</template>

<script setup lang="ts">

import { nextTick, onMounted, ref, toRaw, watch } from 'vue'
import type Subproject from '@/model/Subproject'
import { API, PathSegment } from '@/client/axios'
import { ResponseKey } from '@/model/ResponseWrapper'
import { Units, Type } from '@/model/Type'
import BaseButton from '@/components/base/BaseButton.vue'
import router, { routeNames } from '@/router'
import DiscountCard from '@/components/project/DiscountCard.vue'
import type Discount from '@/model/Discount'
import { useRoute } from 'vue-router'
import type Project from '@/model/Project'
import WorkReportCard from '@/components/project/WorkReportCard.vue'
import MaterialReportCard from '@/components/project/MaterialReportCard.vue'
import BaseContainer from '@/components/base/BaseContainer.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import BaseSite from '@/components/base/BaseSite.vue'
import Spinner from '@/components/base/Spinner.vue'
import GenerateInvoiceModal from '@/components/project/GenerateInvoiceModal.vue'
import { Utils } from '@/client/utils'
import { KeycloakService } from '@/service/keycloakService'
import { TenantSettings } from '@/stores/TenantSettings'
import TransportReportCard from '@/components/project/TransportReportCard.vue'


const route = useRoute();
const projectId = route.params.id as string;

const project = ref<Project>();
const subprojects = ref<Subproject[]>();
const subprojectsLoading = ref(true);

const selectedSubproject = ref<Subproject>();

const discounts = ref<Discount[]>();
const discountsLoading = ref(true);

const pdfModal = ref(GenerateInvoiceModal);
const modalOpen = ref(false);
const subprojectListContainer = ref<any>(null);

watch(() => subprojects.value, async newVal => {
  discountsLoading.value = true;
  if (!newVal || newVal.length == 0) return;
  selectedSubproject.value = newVal[newVal.length - 1];
  await nextTick();
  if (subprojectListContainer.value) {
    subprojectListContainer.value.scrollLeft = subprojectListContainer.value.scrollWidth;
  }
  await loadDiscounts();
}, {
  immediate: true
});

async function loadDiscounts() {
  try {
    if (!selectedSubproject.value) return;
    const subproject = toRaw(selectedSubproject.value);
    const response = await API.get<Discount[]>(PathSegment.SUBPROJECTS, subproject.id, PathSegment.DISCOUNTS);
    if (response.key == ResponseKey.OK) {
      discounts.value = response.data
    } else {
      console.error(`Discounts could not be loaded: ${response.message}`)
    }
    discountsLoading.value = false;
  } catch (error) {
    console.error(`Discounts could not be loaded: ${error}`);
  }
}

async function loadProject() {
  try {
    const response = await API.getWithParameters<Project>(PathSegment.PROJECTS, projectId, new Map<string,string>([['includeCustomer', 'true']]));
    if (response.key == ResponseKey.OK) {
      project.value = response.data;
    } else {
      console.error(`Project could not be loaded: ${response.message}`)
    }
    subprojectsLoading.value = false;
  } catch (error) {
    console.error(`Project could not be loaded: ${error}`);
  }
}

async function loadSubprojects() {
  try {
    const response = await API.get<Subproject[]>(PathSegment.PROJECTS, projectId, PathSegment.SUBPROJECTS);
    if (response.key == ResponseKey.OK) {
      subprojects.value = response.data;
    } else {
      console.error(`Subprojects could not be loaded: ${response.message}`)
    }
    subprojectsLoading.value = false;
  } catch (error) {
    console.error(`Subprojects could not be loaded: ${error}`);
  }
}

function onChangeSubproject(subproject: Subproject) {
  selectedSubproject.value = subproject;
  loadDiscounts();
}

onMounted(() => {
  loadProject();
  loadSubprojects();
});

function onEditProject() {
  router.push({name: routeNames.EDIT_PROJECT, params: {id: projectId}});
}

function onEditCustomer() {
  router.push({name: routeNames.EDIT_CUSTOMER, params: {id: project.value?.customer.id}});
}

function onAddSubproject() {
  router.push({name: routeNames.CREATE_SUBPROJECT, query: {projectId: projectId}});
}

function onEditSubproject() {
  router.push({name: routeNames.EDIT_SUBPROJECT, params: {id: selectedSubproject.value?.id}});
}

const openModal = () => {
  if (pdfModal.value) {
    modalOpen.value = true;
    pdfModal.value.openModal();
  }
};

function onModalClose() {
  modalOpen.value = false;
}

async function onGeneratePdf() {
  openModal();
}

function subprojectCostLimitPercentage(): string {
  if (!selectedSubproject.value || selectedSubproject.value!.costLimit == 0) return '';
  const percentage = (selectedSubproject.value!.currentCosts / selectedSubproject.value!.costLimit) * 100;
  return `(${percentage.toFixed(0)}${Type.getImmutableUnit(Units.PERCENTAGE).abbreviation()})`;
}

const scrollTo = (id: string) => {
  const element = document.getElementById(id)
  element?.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
    inline: 'center'
  })
}

</script>

<style scoped>

.overview-nav-btn {
  @apply h-full self-stretch aspect-square p-0 bg-transaprent border-none hover:bg-transaprent hover:text-primary active:text-white active:bg-primary;
}

.offset-scroll-into-view {
  @apply scroll-my-[theme(spacing.headerHeight)];
}

</style>