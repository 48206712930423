export default interface ResponseWrapper<T> {
    data: T,
    key: ResponseKey,
    message: string
}

export function getDefaultResponseWrapper(): ResponseWrapper<null> {
    return new class implements ResponseWrapper<null> {
        data = null;
        key = ResponseKey.NONE;
        message = '';
    }
}

export enum ResponseKey {
    NONE = 'NONE',
    OK = 'OK',
    CREATED = 'CREATED',
    UPDATED = 'UPDATED',
    DELETED = 'DELETED'
}