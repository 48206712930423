<template>

  <base-container>
    <div class="flex justify-between mb-5">
      <base-heading>
        <div class="title-icon-with-background">
          <base-icon>percent</base-icon>
        </div>
        Rabatte & Aufschläge
      </base-heading>
      <base-button v-if="!locked" class="mb-auto rounded-lg" type="icon" look="secondary" @click="onAddDiscount">
        <base-icon>add</base-icon>
      </base-button>
    </div>

    <spinner v-if="loading" />

    <div v-else>
      <div v-if="discounts.length == 0">
        <p class="text-bodyMediumBold">Keine Einträge vorhanden</p>
      </div>

      <div v-else>

        <div class="grid gap-4">
          <!-- Header -->
          <div class="bg-grey-mid p-4 md:rounded-md rounded border border-grey-mid grid grid-cols-3 gap-4">
            <div class="col-span-2 overflow-hidden">Name</div>
            <div class="col-span-1 overflow-hidden text-right">Menge</div>
          </div>
          <!-- Content -->
          <div v-for="discount in discounts" :key="discount.id" class="grid grid-cols-3 gap-4" @click="onEditDiscount(discount.id)" :class="{'selectable-md': !locked, 'unselectable-md': locked}">
            <div class="col-span-2 overflow-hidden">{{Type.getDiscountType(discount.discountTypeId).name}}</div>
            <div class="col-span-1 overflow-hidden text-right">{{Type.getDiscountType(discount.discountTypeId).deduction ? '-' : '+'}} {{Type.getDiscountType(discount.discountTypeId).relative ? discount.amount : Utils.formatCurrency(discount.amount)}} {{Type.getImmutableUnit(Type.getDiscountType(discount.discountTypeId).relative ? Units.PERCENTAGE : Units.SWISS_FRANCS).abbreviation()}}</div>
          </div>
        </div>

      </div>

    </div>
  </base-container>

</template>

<script setup lang="ts">
import router, { routeNames } from '@/router'
import type Discount from '@/model/Discount'
import { Type, Units } from '../../model/Type'
import Spinner from '@/components/base/Spinner.vue'
import { Utils } from '../../client/utils'

const props = defineProps<{
  discounts: Discount[];
  loading: boolean;
  subprojectId: string;
  locked: boolean;
}>();

async function onAddDiscount() {
  await router.push({name: routeNames.CREATE_DISCOUNT, query: {subprojectId: props.subprojectId}})
}

async function onEditDiscount(id: string) {
  if (props.locked) return;
  await router.push({name: routeNames.EDIT_DISCOUNT, params: {id: id}, query: {subprojectId: props.subprojectId}})
}

</script>

<style scoped>

</style>