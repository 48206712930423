import './assets/styles.css';

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { plugin, defaultConfig } from '@formkit/vue';
import formkitConfig from '../formkit.config';

import App from './App.vue';
import router from './router';

import BaseContainer from './components/base/BaseContainer.vue';
import BaseCard from './components/base/BaseCard.vue';
import BaseHeading from './components/base/BaseHeading.vue';
import BaseButton from './components/base/BaseButton.vue';
import BaseIcon from './components/base/BaseIcon.vue';
import BaseDropdown from './components/base/BaseDropdown.vue';
import ToastPlugin from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-bootstrap.css';
import { KeycloakService } from '@/service/keycloakService'

const app = createApp(App)

app.use(createPinia())
app.use(router)
app.use(plugin, defaultConfig(formkitConfig));
app.use(ToastPlugin);

app.component('BaseContainer', BaseContainer);
app.component('BaseCard', BaseCard);
app.component('BaseHeading', BaseHeading);
app.component('BaseButton', BaseButton);
app.component('BaseIcon', BaseIcon);
app.component('BaseDropdown', BaseDropdown);


// initialize Keycloak before everything else
KeycloakService.init().then(authenticated  => {
  if (authenticated) {
    app.mount('#app');
  } else {
    window.location.reload();
  }
}).catch(error => {
  console.error('Keycloak initialization failed:', error);
});
