<template>
  <base-site :title="titleText" :show-button="!!discountId" button-icon="delete" button-look="secondary-light" @button-action="openModal">

    <base-container class="mb-betweenElements">
      <spinner v-if="discountLoading"></spinner>
      <div v-else>
        <FormKit type="select" name="discountType" id="discountType" label="Typ*" validation="required" :options="Utils.toSelectOptions(Type.getAllDiscountTypes())" v-model="selectedDiscountTypeId" />
        <FormKit type="number" number step="any" name="amount" id="amount" label="Menge*" validation="required" v-model="amount" />
        <FormKit type="date" name="expirationDate" id="expirationDate" label="Ablaufdatum" v-model="expirationDate" />
      </div>
    </base-container>

    <div class="flex md:flex-row flex-col">
      <base-button class="md:mr-auto md:mx-0 mx-betweenElements md:w-buttonXLarge" @click="onSubmit">
        <spinner-small v-if="requesting" />
        <div v-else>{{submitText}}</div>
      </base-button>
      <base-button class="md:ml-auto md:mx-0 md:mt-0 mt-8 mx-betweenElements md:w-buttonXLarge" look="secondary-light" @click="onCancel">
        Abbrechen
      </base-button>
    </div>

    <confirmation-modal ref="confirmDeletionModal"
                        title="Rabatt Löschen"
                        confirm-text="Löschen"
                        @on-confirm="onDelete">
      Soll der Rabatt wirklich gelöscht werden?
    </confirmation-modal>
  </base-site>

</template>

<script setup lang="ts">

import { onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import router from '@/router'
import BaseButton from '@/components/base/BaseButton.vue'
import ConfirmationModal from '@/components/base/ConfirmationModal.vue'
import Spinner from '@/components/base/Spinner.vue'
import BaseSite from '@/components/base/BaseSite.vue'
import { DateConverter } from '@/client/DateConverter'
import { Utils } from '@/client/utils'
import { Type } from '@/model/Type'
import type Discount from '@/model/Discount'
import { API, PathSegment } from '@/client/axios'
import SpinnerSmall from '@/components/base/SpinnerSmall.vue'
import { useToast } from 'vue-toast-notification'

const route = useRoute();

const requesting = ref(false);
const discountLoading = ref(true);
const discountId = route.params.id as string;
const subprojectId = route.query.subprojectId as string;
const existingDiscount = ref<Discount>();

const selectedDiscountTypeId = ref('');
const amount = ref(0);
const expirationDate = ref('')

const titleText = ref('Materialposition');
const submitText = ref<string>();

const confirmDeletionModal = ref(ConfirmationModal);

const DISCOUNT = 'Rabatt';

const $toast = useToast();

async function onSubmit() {
  if (discountId) await onUpdate();
  else await onCreate();
}

async function onCreate() {
  if (requesting.value) return;
  if (!checkInputs()) return
  requesting.value = true;
  const response = await API.createDataObject<Discount>(PathSegment.DISCOUNTS,{
    id: '',
    subprojectId: subprojectId,
    discountTypeId: selectedDiscountTypeId.value,
    amount: amount.value,
    expirationDate: DateConverter.convertToUnixTimestamp(expirationDate.value),
    total: 0,
    createdAt: 0,
    updatedAt: 0
  }, DISCOUNT);
  if (response) router.go(-1);
  requesting.value = false;
}

async function onUpdate() {
  if (requesting.value) return;
  requesting.value = true;
  const response = await API.updateDataObject<Discount>(PathSegment.DISCOUNTS, {
    id: discountId,
    subprojectId: subprojectId,
    discountTypeId: selectedDiscountTypeId.value,
    amount: amount.value,
    expirationDate: DateConverter.convertToUnixTimestamp(expirationDate.value),
    total: 0,
    createdAt: 0,
    updatedAt: 0
  }, DISCOUNT);
  if (response) router.go(-1);
  requesting.value = false;
}

async function loadDiscount() {
  if (!discountId) {
    discountLoading.value = false;
    return;
  }
  discountLoading.value = true;
  const response = await API.getDataObject<Discount>(PathSegment.DISCOUNTS, discountId, DISCOUNT);
  discountLoading.value = false;
  if (!response) return;
  existingDiscount.value = response;
  selectedDiscountTypeId.value = response.discountTypeId;
  amount.value = response.amount;
  expirationDate.value = response.expirationDate == 0 ? '' : DateConverter.convertToLocalDateISO(response.expirationDate);
}

watch(() => selectedDiscountTypeId.value, newVal => {
  if (!newVal && amount.value == 0) return;
  if (discountId && newVal == existingDiscount.value?.discountTypeId) amount.value = existingDiscount.value?.amount
  else amount.value = Type.getDiscountType(newVal).standardAmount;
}, {
  immediate: true
});

function checkInputs() {
  if (!selectedDiscountTypeId.value || selectedDiscountTypeId.value == '') {
    $toast.info('Kein Rabatt-Typ gewählt');
    return false;
  }
  if (!amount.value || amount.value == 0) {
    $toast.info('Keine Menge eingegeben');
    return false;
  }
  return true;
}

async function onDelete() {
  if (!discountId || requesting.value) return;
  if (await API.deleteDataObject(PathSegment.DISCOUNTS, discountId)) router.go(-1);
}

function onCancel() {
  router.go(-1);
}

const openModal = () => {
  if (confirmDeletionModal.value) {
    confirmDeletionModal.value.openModal();
  }
};

onMounted(async () => {
  if (discountId) {
    // edit
    titleText.value = 'Rabatt bearbeiten';
    submitText.value = 'Rabatt speichern';
    await loadDiscount();
  } else {
    // create
    titleText.value = 'Rabatt erstellen';
    submitText.value = 'Rabatt erfassen';
    discountLoading.value = false;
  }
});

</script>

<style scoped>

</style>