<template>
  <base-container>
    <base-heading>
      <div class="title-icon-with-background">
        <base-icon>assignment</base-icon>
      </div>
      Kategorien / Beschreibung
    </base-heading>

    <div class="flex flex-row">
      <div class="w-full">
        <FormKit type="textarea" name="description" id="description" label="Detailbeschreibung" v-model="descriptionText" />
      </div>
      <button class="pl-betweenElements" @click="openTranscriptionModal">
        <base-icon size="iconSizeLarge">mic</base-icon>
      </button>
    </div>

    <div v-for="(sentence, index) of sentences" :key="index" class="flex border border-grey-mid md:rounded py-3 px-7 mb-betweenElements"> <!-- class="selectable" @click="openModal(sentence)" -->
      {{InvoiceInscriptionBuilder.buildSentence(sentence)}}
      <base-icon class="ml-auto cursor-pointer text-primary" @click="onDelete(index)">delete</base-icon>
    </div>

    <div class="flex md:flex-row flex-col mb-betweenElements">
<!--      Fixme Temporary disabled until Pauschale Arbeiten implementation-->
<!--      <base-button type="icon" class="md:mr-betweenElements md:mb-0 mb-8" look="secondary" @click="openModal([])" :class="showLatestDescriptions ? 'md:mr-auto' : 'md:mr-betweenElements'">-->
<!--        <base-icon class="mr-2">add</base-icon>-->
<!--        Kategorien hinzufügen-->
<!--      </base-button>-->
      <base-button v-if="!showLatestDescriptions" type="icon" class="md:mr-auto md:mb-0 mb-8" look="secondary" @click="displayLatestDescriptions">
        <base-icon class="mr-5">content_copy</base-icon>
        <span>Von letzen Einträgen kopieren</span>
      </base-button>
      <base-button type="mixed" class="md:ml-auto" look="secondary" v-if="sentences.length > 0" @click="convertToText()">
        <base-icon class="mr-2">edit</base-icon>
        Kategorien zu Text konvertieren
      </base-button>
    </div>

    <div v-if="showLatestDescriptions">
      <div v-if="descriptions.length > 0">
        <button class="w-full mb-betweenElements selectable-lg text-start" v-for="description of descriptions" :key="description" @click="onCopyLatestDescription(description)">
          {{description}}
        </button>
      </div>
      <div v-else class="text-bodyMediumBold text-grey-dark mb-betweenElements">Es gibt noch keine Einträge zum kopieren</div>
    </div>

    <categories-modal ref="modalRef" :type="ReportType.WORK" @receive-modal-categories="receiveCategoriesFromModal"></categories-modal>
    <transcription-modal ref="transcriptionModalRef"
                         :existing-text="descriptionText"
                         @on-transcribed="onTranscribed" />
  </base-container>
</template>

<script setup lang="ts">
import { defineExpose, ref, watch } from 'vue'
import CategoriesModal from '@/components/workReportPosition/CategoriesModal.vue'
import type Category from '@/model/Category'
import BaseContainer from '@/components/base/BaseContainer.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import { ReportType } from '@/model/Type'
import { InvoiceInscriptionBuilder } from '@/client/InvoiceInscriptionBuilder'
import { API, PathSegment } from '@/client/axios'
import { useToast } from 'vue-toast-notification'
import { Utils } from '@/client/utils'
import { ResponseKey } from '@/model/ResponseWrapper'
import TranscriptionModal from '@/components/workReportPosition/TranscriptionModal.vue'

const props = defineProps<{
  existingCategories: Category[][];
  existingDescription: string;
  subprojectId: string;
}>();

const sentences = ref<Category[][]>([]);
const descriptionText = ref('');

const showLatestDescriptions = ref(false);
const descriptions = ref<string[]>([]);

const $toast = useToast();

const emits = defineEmits(['receive-categories']);

watch(() => props.existingCategories, newVal => {
  if (!newVal) return;
  sentences.value = newVal;
}, {
  immediate: true
});

watch(() => props.existingDescription, newVal => {
  if (!newVal) return;
  descriptionText.value = newVal;
}, {
  immediate: true
});

async function onCopyLatestDescription(description: string) {
  descriptionText.value = description;
  showLatestDescriptions.value = false;
}

function onDelete(index: number) {
  sentences.value.splice(index, 1);
}

function receiveCategoriesFromModal(categories: Category[]) {
  sentences.value.push(categories);
}


// Reference to the Modal component
const modalRef = ref(CategoriesModal);

const openModal = (categories: Category[]) => {
  if (modalRef.value) {
    modalRef.value.openModal(categories);
  }
};

const transcriptionModalRef = ref(TranscriptionModal);
const openTranscriptionModal = () => {
  if (transcriptionModalRef.value) {
    transcriptionModalRef.value.openModal();
  }
};

function onTranscribed(text: string) {
  if (!text || text == '') return;
  descriptionText.value = text;
}

function convertToText() {
  descriptionText.value = InvoiceInscriptionBuilder.build(sentences.value);
}


// emitting selected categories to parent component
function emitCategories() {
  const finalSentences = sentences.value.map(sentence => sentence.map(cat => cat.id));
  emits('receive-categories', finalSentences, descriptionText.value);
}

defineExpose({
  emitCategories
})

async function displayLatestDescriptions() {
  try {
    const response = await API.getWithParameters<string[]>(PathSegment.SUBPROJECTS, props.subprojectId, PathSegment.LATEST_REPORT_DESCRIPTIONS, new Map<string,string>([['limit', '3']]));
    if (response.key == ResponseKey.OK) {
      descriptions.value = response.data;
      showLatestDescriptions.value = true;
    } else {
      $toast.error(`Holen der Einträge fehlgeschlagen: ${response.message}`);
    }
  } catch (error) {
    $toast.error(`Holen der Einträge fehlgeschlagen: ${Utils.getError(error)}`);
  }
}

//endregion
</script>

<style scoped>

</style>