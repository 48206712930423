import { API, PathSegment } from '@/client/axios'
import { ResponseKey } from '@/model/ResponseWrapper'
import type Category from '@/model/Category'
import { useToast } from 'vue-toast-notification'

const $toast = useToast();

export async function loadRootCategories(categoryTypeId: string) {
  try {
    const response = await API.getWithParameters<Category[]>(PathSegment.CATEGORIES, new Map<string,string>([['categoryType', categoryTypeId.toString()]]));
    if (response.key == ResponseKey.OK) {
      return response.data
    } else {
      $toast.error(`${response.message} (${response.key})`);
      console.error(`Categories could not be loaded: ${response.message}`)
    }
  } catch (error) {
    $toast.error(`Kategorien konnten nicht geladen werden (${error})`);
    console.error(`Categories could not be loaded: ${error}`);
  }

  return [];
}

export async function loadCategory(categoryId: string) {
  try {
    const response = await API.get<Category>(PathSegment.CATEGORIES, categoryId);
    if (response.key == ResponseKey.OK) {
      return response.data
    } else {
      $toast.error(`${response.message} (${response.key})`);
      console.error(`Category could not be loaded: ${response.message}`)
    }
  } catch (error) {
    $toast.error(`Kategorie konnte nicht geladen werden (${error})`);
    console.error(`Category could not be loaded: ${error}`);
  }
}

export async function loadCategoryChildren(categoryId: string) {
  try {
    const response = await API.get<Category[]>(PathSegment.CATEGORIES, categoryId, PathSegment.CHILDREN);
    if (response.key == ResponseKey.OK) {
      return response.data
    } else {
      $toast.error(`${response.message} (${response.key})`);
      console.error(`Categories could not be loaded: ${response.message}`)
    }
  } catch (error) {
    $toast.error(`Kategorien konnten nicht geladen werden (${error})`);
    console.error(`Categories could not be loaded: ${error}`);
  }

  return [];
}