import { createRouter, createWebHistory } from 'vue-router';
import DashboardView from '../views/dashboard/DashboardView.vue';
import CreateCompany from '../views/company/CreateCompanyView.vue';
import LoginView from '../views/login/LoginView.vue';
import InitializationView from '@/views/initial/InitializationView.vue'
import ProjectView from '@/views/project/ProjectView.vue'
import SubprojectView from '@/views/subproject/CreateEditSubprojectView.vue'
import EditProjectView from '@/views/project/CreateEditProjectView.vue'
import CreateEditReportPosition from '@/views/reportPosition/CreateEditWorkReport.vue'
import CreateEditMaterialReport from '@/views/reportPosition/CreateEditMaterialReport.vue'
import CreateEditCustomer from '@/views/customer/CreateEditCustomer.vue'
import CustomerView from '@/views/customer/CustomerView.vue'
import AllCustomersView from '@/views/customer/AllCustomersView.vue'
import CreateEditDiscount from '@/views/discount/CreateEditDiscount.vue'
import CreateEditCategory from '@/views/category/CreateEditCategory.vue'
import AllCategoriesView from '@/views/category/AllCategoriesView.vue'
import SettingsView from '@/views/settings/SettingsView.vue'
import PresetsAndTypesView from '@/views/presetsAndTypes/PresetsAndTypesView.vue'
import AllProjectsView from '@/views/project/AllProjectsView.vue'
import UseCaseSelectionView from '@/views/UseCaseSelectionView.vue'

const routeNames = {
  DASHBOARD: "dashboard",
  CREATE_COMPANY: "createCompany",
  EDIT_COMPANY: "editCompany",
  LOGIN: "login",
  INIT: "init",
  CUSTOMER: "customer",
  CUSTOMERS: "customers",
  CREATE_CUSTOMER: "createCustomer",
  EDIT_CUSTOMER: "editCustomer",
  PROJECT: "project",
  PROJECTS: "projects",
  CREATE_PROJECT: "createProject",
  EDIT_PROJECT: "editProject",
  WORK_REPORT: "workReport",
  MATERIAL_REPORT: "materialReport",
  CREATE_SUBPROJECT: "createSubproject",
  EDIT_SUBPROJECT: "editSubproject",
  CREATE_WORK_REPORT: "createWorkReport",
  EDIT_WORK_REPORT: "editWorkReport",
  CREATE_MATERIAL_REPORT: "createMaterialReport",
  EDIT_MATERIAL_REPORT: "editMaterialReport",
  CREATE_DISCOUNT: "createDiscount",
  EDIT_DISCOUNT: "editDiscount",
  CATEGORIES_ROOT: "categoriesRoot",
  CATEGORIES_CHILD: "categoriesChild",
  CREATE_CATEGORY: "createCategory",
  EDIT_CATEGORY: "editCategory",
  PRESETS_AND_TYPES: "presetsAndTypes",
  SETTINGS: "settings",
  USE_CASE: "useCase",
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      alias: '/dashboard',
      name: routeNames.DASHBOARD,
      component: DashboardView
    },
    {
      path: '/company/create',
      name: routeNames.CREATE_COMPANY,
      component: CreateCompany
    },
    {
      path: '/company/edit',
      name: routeNames.EDIT_COMPANY,
      component: CreateCompany
    },
    {
      path: '/login',
      name: routeNames.LOGIN,
      component: LoginView
    },
    {
      path: '/init',
      name: routeNames.INIT,
      component: InitializationView
    },
    {
      path: '/customer/:id',
      name: routeNames.CUSTOMER,
      component: CustomerView
    },
    {
      path: '/customers',
      name: routeNames.CUSTOMERS,
      component: AllCustomersView
    },
    {
      path: '/customer/create',
      name: routeNames.CREATE_CUSTOMER,
      component: CreateEditCustomer
    },
    {
      path: '/customer/:id/edit',
      name: routeNames.EDIT_CUSTOMER,
      component: CreateEditCustomer
    },
    {
      path: '/project/:id',
      name: routeNames.PROJECT,
      component: ProjectView
    },
    {
      path: '/projects',
      name: routeNames.PROJECTS,
      component: AllProjectsView
    },
    {
      path: '/project/create',
      name: routeNames.CREATE_PROJECT,
      component: EditProjectView
    },
    {
      path: '/project/:id/edit',
      name: routeNames.EDIT_PROJECT,
      component: EditProjectView
    },
    {
      path: '/subproject/create',
      name: routeNames.CREATE_SUBPROJECT,
      component: SubprojectView
    },
    {
      path: '/subproject/:id/edit',
      name: routeNames.EDIT_SUBPROJECT,
      component: SubprojectView
    },
    {
      path: '/workReport/create',
      name: routeNames.CREATE_WORK_REPORT,
      component: CreateEditReportPosition
    },
    {
      path: '/workReport/:id/edit',
      name: routeNames.EDIT_WORK_REPORT,
      component: CreateEditReportPosition
    },
    {
      path: '/materialReport/create',
      name: routeNames.CREATE_MATERIAL_REPORT,
      component: CreateEditMaterialReport
    },
    {
      path: '/materialReport/:id/edit',
      name: routeNames.EDIT_MATERIAL_REPORT,
      component: CreateEditMaterialReport
    },
    {
      path: '/discount/create',
      name: routeNames.CREATE_DISCOUNT,
      component: CreateEditDiscount
    },
    {
      path: '/discount/:id/edit',
      name: routeNames.EDIT_DISCOUNT,
      component: CreateEditDiscount
    },
    // {
    //   path: '/categories',
    //   name: routeNames.CATEGORIES_ROOT,
    //   component: AllCategoriesView
    // },
    // {
    //   path: '/categories/:id',
    //   name: routeNames.CATEGORIES_CHILD,
    //   component: AllCategoriesView
    // },
    {
      path: '/categories',
      name: routeNames.CATEGORIES_ROOT,
      component: AllCategoriesView,
      children: [
        {
          path: ':ids*',
          name: routeNames.CATEGORIES_CHILD,
          component: AllCategoriesView
        }
      ]
    },
    {
      path: '/category/create',
      name: routeNames.CREATE_CATEGORY,
      component: CreateEditCategory
    },
    {
      path: '/category/:id/edit',
      name: routeNames.EDIT_CATEGORY,
      component: CreateEditCategory
    },
    {
      path: '/presetsAndTypes',
      name: routeNames.PRESETS_AND_TYPES,
      component: PresetsAndTypesView
    },
    {
      path: '/settings',
      name: routeNames.SETTINGS,
      component: SettingsView
    },
    {
      path: '/useCase',
      name: routeNames.USE_CASE,
      component: UseCaseSelectionView
    },
  ]
})

export default router
export {
  routeNames
}
