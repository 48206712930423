<template>

  <spinner v-if="reportPositionLoading"></spinner>

  <base-site v-else :title="titleText" :show-button="!!reportPositionId" button-icon="delete" button-look="secondary-light" @button-action="openModal">

    <base-container class="mb-betweenElements">
      <base-heading>
        <div class="title-icon-with-background">
          <base-icon>access_time_filled</base-icon>
        </div>
        Allgemein
      </base-heading>
      <FormKit type="date" name="date" id="date" label="Datum*" validation="required" v-model="date" />
    </base-container>

    <category-card ref="categoryCard"
                   :existing-categories="existingReportPosition?.categories ?? []"
                   :existing-description="existingReportPosition?.description ?? ''"
                   :subproject-id="subprojectId"
                   @receive-categories="receiveCategoriesFromCategoryCard"
                   class="mb-betweenElements">
    </category-card>

    <worker-card ref="workerCard"
                 :existing-workers="existingReportPosition?.workers ?? []"
                 @receive-workers="receiveWorkersFromWorkerCard"
                 class="mb-betweenElements">
    </worker-card>

    <div class="flex md:flex-row flex-col">
      <base-button class="md:mr-auto md:mb-0 md:mx-0 mx-betweenElements md:w-buttonXLarge" @click="onSubmit">
        <spinner-small v-if="requesting" />
        <div v-else>{{submitText}}</div>
      </base-button>
      <base-button class="md:ml-auto md:mx-0 mx-betweenElements mt-8 md:mt-0 md:w-buttonXLarge" look="secondary-light" @click="onCancel">
        Abbrechen
      </base-button>
    </div>

    <confirmation-modal ref="confirmDeletionModal"
                        title="Rapportposition Löschen"
                        confirm-text="Löschen"
                        @on-confirm="onDelete">
      Soll die Rapportposition wirklich gelöscht werden?
    </confirmation-modal>
  </base-site>

</template>

<script setup lang="ts">

import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { API, PathSegment } from '@/client/axios'
import { ResponseKey } from '@/model/ResponseWrapper'
import WorkerCard from '@/components/workReportPosition/WorkerCard.vue'
import CategoryCard from '@/components/workReportPosition/CategoryCard.vue'
import router from '@/router'
import type { Worker, WorkReportPositionRequest, WorkReportPositionResponse } from '@/model/WorkReportPosition'
import BaseButton from '@/components/base/BaseButton.vue'
import { useToast } from 'vue-toast-notification'
import ConfirmationModal from '@/components/base/ConfirmationModal.vue'
import Spinner from '@/components/base/Spinner.vue'
import BaseSite from '@/components/base/BaseSite.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import { DateConverter } from '@/client/DateConverter'
import SpinnerSmall from '@/components/base/SpinnerSmall.vue'

const WORK_REPORT = 'Rapportposition';
const route = useRoute();

const requesting = ref(false);
const reportPositionLoading = ref(true);
const reportPositionId = route.params.id as string;
const subprojectId = route.query.subprojectId as string;
const projectId = route.query.projectId as string;
const existingReportPosition = ref<WorkReportPositionResponse>();
const date = ref(DateConverter.getCurrentLocalDateISO())

const titleText = ref(WORK_REPORT);
const submitText = ref<string>();

const categoryCard = ref(CategoryCard);
const categories = ref<string[][]>([]);
const description = ref('');

const workerCard = ref(WorkerCard);
const workers = ref<Worker[]>([]);

const confirmDeletionModal = ref(ConfirmationModal);

const $toast = useToast();

function receiveCategoriesFromCategoryCard(categoryIds: string[][], descriptionText: string) {
  categories.value = categoryIds;
  description.value = descriptionText;
}

function getCategories() {
  if (categoryCard.value) {
    categoryCard.value.emitCategories();
  }
}

function receiveWorkersFromWorkerCard(emittedWorkers: Worker[]) {
  workers.value = emittedWorkers;
}

function getWorkers() {
  if (workerCard.value) {
    workerCard.value.emitWorkers();
  }
}

async function onSubmit() {
  getCategories();
  if (categories.value.length <= 0 && description.value == '') {
    $toast.error('Keine Kategorie oder Beschreibung hinzugefügt');
    return;
  }
  getWorkers();
  if (workers.value.length <= 0) {
    $toast.error('Kein Arbeiter hinzugefügt');
    return;
  }
  if (!subprojectId) {
    $toast.error('Keine Rechnung zugewiesen');
    console.error('subproject not selected!');
    return;
  }
  if (reportPositionId) await onUpdate();
  else await onCreate();
}

async function onCreate() {
  if (requesting.value) return;
  requesting.value = true;
  const response = await API.createDataObject<WorkReportPositionResponse, WorkReportPositionRequest>(
    PathSegment.WORK_REPORT_POSITIONS,
    {
      id: null,
      subprojectId: subprojectId,
      date: DateConverter.convertToUnixTimestamp(date.value),
      description: description.value,
      categoryIds: categories.value,
      workers: workers.value
    }, WORK_REPORT);
  if (response) router.go(-1);
  requesting.value = false;
}

async function onUpdate() {
  if (requesting.value) return;
  if (!reportPositionId) {
    $toast.error('Aktuallisieren nicht möglich, keine Raport ID vorhanden');
    console.error('Update failed: ReportPositionId not set');
    return;
  }

  requesting.value = true;
  const response = await API.updateDataObject<WorkReportPositionResponse, WorkReportPositionRequest>(
    PathSegment.WORK_REPORT_POSITIONS,
    {
      id: reportPositionId,
      subprojectId: subprojectId,
      date: DateConverter.convertToUnixTimestamp(date.value),
      description: description.value,
      categoryIds: categories.value,
      workers: workers.value
    }, WORK_REPORT);
  if (response) router.go(-1);
  requesting.value = false;
}

async function loadReportPosition() {
  const response = await API.getDataObject<WorkReportPositionResponse>(PathSegment.WORK_REPORT_POSITIONS, reportPositionId, WORK_REPORT);
  if (!response) return;

  existingReportPosition.value = response;
  date.value = DateConverter.convertToLocalDateISO(response.date);

  reportPositionLoading.value = false;
}

async function onDelete() {
  try {
    const response = await API.delete(PathSegment.WORK_REPORT_POSITIONS, reportPositionId);
    if (response.key == ResponseKey.DELETED) {
      router.go(-1);
    } else {
      $toast.error(response.message);
      console.error(response.message);
    }
  } catch (error) {
    $toast.error('Fehler beim löschen der Raportposition');
    console.error(error);
  }
  reportPositionLoading.value = false;
}

function onCancel() {
  router.go(-1);
}

const openModal = () => {
  if (confirmDeletionModal.value) {
    confirmDeletionModal.value.openModal();
  }
};

onMounted(async () => {
  if (reportPositionId) {
    // edit
    titleText.value = 'Rapportposition bearbeiten';
    submitText.value = 'Position speichern';
    await loadReportPosition();
  } else {
    // create
    titleText.value = 'Rapportposition erstellen';
    submitText.value = 'Position erfassen';
    reportPositionLoading.value = false;
  }
});

</script>

<style scoped>

</style>