<template>

  <base-site title="Benutzungszweck auswählen">
    <base-container>

      <div v-if="KeycloakService.isAdmin()">
        <div class="flex flex-row mb-betweenElements">
          <button :class="`${selected == 0 ? 'selected' : 'selectable-md'} rounded-md p-7 w-1/2 mr-betweenElements`" @click="onSelect(0)">
            <div class="flex flex-row items-center mb-5">
              <base-icon class="mr-5">handyman</base-icon>
              <p class="text-bodyMediumBold">Handwerk</p>
              <base-icon v-if="selected == 0" class="ml-auto">check</base-icon>
            </div>
            <p class="text-bodySmallNormal text-left">Ihr Geschäft sind handwerkliche Dinstleistungen</p>
          </button>
          <button :class="`${selected == 1 ? 'selected' : 'selectable-md'} rounded-md p-7 w-1/2`" @click="onSelect(1)">
            <div class="flex flex-row items-center mb-5">
              <base-icon class="mr-5">local_shipping</base-icon>
              <p class="text-bodyMediumBold">Transport</p>
              <base-icon v-if="selected == 1" class="ml-auto">check</base-icon>
            </div>
            <p class="text-bodySmallNormal text-left">Ihr Geschäft sind Transporte oder Lieferungen</p>
          </button>
        </div>

        <base-button @click="onSubmit">
          Weiter
        </base-button>
      </div>


      <div v-else>
        <p class="text-bodyMediumBold text-grey-dark mb-11 mt-11">Ihre Firma wurde noch nicht erstellt. Bitte melden Sie sich als Administrator an.</p>
        <base-button @click="KeycloakService.logout()">Logout</base-button>
      </div>

    </base-container>
  </base-site>

</template>

<script setup lang="ts">

import BaseSite from '@/components/base/BaseSite.vue'
import BaseContainer from '@/components/base/BaseContainer.vue'
import { KeycloakService } from '@/service/keycloakService'
import BaseButton from '@/components/base/BaseButton.vue'
import { ref } from 'vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import { API, PathSegment } from '@/client/axios'
import { UseCase } from '@/model/Settings'
import type Settings from '@/model/Settings'
import { useRouter } from 'vue-router'
import { routeNames } from '@/router'
import { TenantSettings } from '@/stores/TenantSettings'

const router = useRouter();
const selected = ref<number>(0);

async function onSubmit() {
 const response = await API.updateDataObject<Settings>(PathSegment.SETTINGS, getSelectedSettings());
 if (!response) return;
 TenantSettings.setSettings(response);
 await router.push({name: routeNames.CREATE_COMPANY});
}

function onSelect(value: number) {
  selected.value = value;
}

function getSelectedSettings(): Settings {
  switch (selected.value) {
    case 0: return getConstructionSettings();
    case 1: return getTransportSettings();
    default: throw Error("No Selection has been made");
  }
}

function getConstructionSettings(): Settings {
  return {
    tenant: KeycloakService.realm,
    pricesIncludingVat: false,
    distanceTopLogo: 0,
    distanceLogoAddress: 30,
    logoHeight: 45,
    distanceBetweenAddresses: 50,
    paymentDeadline: 30,
    skontoRuntime: 10,
    invoiceTitle: "Rechnung",
    invoiceHeader: undefined,
    invoiceFooter: undefined,
    invoiceWorkTableTitle: "Arbeiten",
    invoiceMaterialTableTitle: "Material",
    aiLanguage: "de",
    aiPrompt: "Arbeitsrapport. Rechtschreibung korrigiert. Komplette Sätze, keine Zeilenumbrüche",
    updatedAt: 0,
    useCase: UseCase.CONSTRUCTION
  }
}

function getTransportSettings(): Settings {
  return {
    tenant: KeycloakService.realm,
    pricesIncludingVat: false,
    distanceTopLogo: 0,
    distanceLogoAddress: 30,
    logoHeight: 45,
    distanceBetweenAddresses: 50,
    paymentDeadline: 30,
    skontoRuntime: 10,
    invoiceTitle: "Rechnung",
    invoiceHeader: undefined,
    invoiceFooter: undefined,
    invoiceWorkTableTitle: "Arbeiten",
    invoiceMaterialTableTitle: "Transporte;;;Ladeort;;;Abladeort",
    aiLanguage: "de",
    aiPrompt: "Namen von Schweizer Ortschaften",
    updatedAt: 0,
    useCase: UseCase.TRANSPORT
  }
}

</script>
