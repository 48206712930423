<template>

  <base-container>
    <div class="flex justify-between mb-5">
      <base-heading>
        <div class="title-icon-with-background">
          <base-icon>handyman</base-icon>
        </div>
        Materialrapport
      </base-heading>
      <base-button v-if="!locked" class="mb-auto rounded-lg" type="icon" look="secondary" @click="onAddMaterialReportPosition()">
        <base-icon>add</base-icon>
      </base-button>
    </div>
    <spinner v-if="positionsLoading" />

    <div v-else>
      <div v-if="materialReportPositions.length == 0">
        <p class="text-bodyMediumBold">Keine Einträge vorhanden</p>
      </div>
      <div v-else>


          <div class="grid gap-4">
            <!-- Header -->
            <div class="bg-grey-mid p-4 md:rounded-md rounded border border-grey-mid grid md:grid-cols-20 grid-cols-8 gap-4">

              <!-- first two columns (large: Datum / Name, small: Datum / Name) -->
              <div class="col-span-2 md:block hidden overflow-hidden">Datum</div>
              <div class="md:col-span-9 col-span-6 overflow-hidden">
                <div class="md:block hidden overflow-hidden">Name</div>
                <div class="md:hidden block text-bodyMediumBold overflow-hidden">Datum</div>
                <div class="md:hidden block overflow-hidden">Name</div>
              </div>

              <!-- second two columns (large: Menge / Einheitspreis, small: Menge / Total) -->
              <div class="col-span-3 md:block hidden overflow-hidden">Menge</div> <!-- First text in large format -->
              <div class="md:col-span-3 col-span-2 md:text-left text-right overflow-hidden">
                <div class="md:block hidden  overflow-hidden">Einheitspreis</div> <!-- Second text in large format -->
                <div class="md:hidden block text-bodyMediumBold overflow-hidden">Menge</div> <!-- First text in small format -->
                <div class="md:hidden block overflow-hidden">Total</div> <!-- Second text in small format -->
              </div>

              <!-- last column (large: Total, small: None) -->
              <div class="md:col-span-3 md:block hidden text-right">Total</div>
            </div>
            <!-- Content -->
            <div v-for="(position, index) in materialReportPositions" :key="index" class="grid md:grid-cols-20 grid-cols-8 gap-4" @click="onEditMaterialReportPosition(position.id)" :class="{'selectable-md': !locked, 'unselectable-md': locked}">

              <!-- first two columns (large: Datum / Name, small: Datum / Name) -->
              <div class="col-span-2 md:block hidden overflow-hidden">{{ DateConverter.convertToLocalDate(position.date) }}</div>
              <div class="md:col-span-9 col-span-6 overflow-hidden">
                <div class="md:block hidden overflow-hidden">{{ position.description || (position.category ? position.category.invoiceInscription : 'Kein Name vorhanden') }}</div>
                <div class="md:hidden block text-bodyMediumBold overflow-hidden">{{ DateConverter.convertToLocalDate(position.date) }}</div>
                <div class="md:hidden block overflow-hidden">{{ position.description || (position.category ? position.category.invoiceInscription : 'Kein Name vorhanden') }}</div>
              </div>

              <!-- second two columns (large: Menge / Einheitspreis, small: Menge / Total) -->
              <div class="col-span-3 md:block hidden overflow-hidden">{{ `${position.quantity} ${decodeUnicodeEscapeSequence(Type.getUnit(position.unitId).abbreviation())}` }}</div> <!-- First text in large format -->
              <div class="md:col-span-3 col-span-2 md:text-left text-right overflow-hidden">
                <div class="md:block hidden  overflow-hidden">{{ `${Utils.formatCurrency(position.unitPrice)} ${Type.getImmutableUnit(Units.SWISS_FRANCS).abbreviation()}/${Type.getUnit(position.unitId).abbreviation()}` }}</div> <!-- Second text in large format -->
                <div class="md:hidden block text-bodyMediumBold overflow-hidden">{{ `${position.quantity} ${decodeUnicodeEscapeSequence(Type.getUnit(position.unitId).abbreviation())}` }}</div> <!-- First text in small format -->
                <div class="md:hidden block overflow-hidden">{{ Utils.formatCurrency(position.total) }}</div> <!-- Second text in small format -->
              </div>

              <!-- last column (large: Total, small: None) -->
              <div class="md:col-span-3 md:block hidden text-right overflow-hidden">{{ Utils.formatCurrency(position.total) }}
                {{ Type.getImmutableUnit(Units.SWISS_FRANCS).abbreviation() }}</div>
            </div>

            <div v-if="totalPages > 1" class="flex flex-row ml-auto">
              <button class="mr-4 transition-colors hover:text-primary" @click="onPreviousPage"><base-icon>navigate_before</base-icon></button>
              <div class="cursor-default">{{offset + 1}} / {{totalPages}}</div>
              <button class="ml-4 transition-colors hover:text-primary" @click="onNextPage"><base-icon>navigate_next</base-icon></button>
            </div>

          </div>

      </div>
    </div>

  </base-container>

</template>

<script setup lang="ts">
import router, { routeNames } from '@/router'
import { Units, Type } from '@/model/Type'
import type { MaterialReportPositionResponse } from '@/model/MaterialReportPosition'
import BaseIcon from '@/components/base/BaseIcon.vue'
import { DateConverter } from '@/client/DateConverter'
import Spinner from '@/components/base/Spinner.vue'
import { ref, watch } from 'vue'
import { getMaterialReportPage } from '@/service/materialReportService'
import { Utils } from '../../client/utils'
import { TenantSettings } from '../../stores/TenantSettings'
import MultiInfoTableLine from '@/components/project/MultiInfoTableLine.vue'

const props = defineProps<{
  subprojectId: string;
  projectId: string;
  locked: boolean;
}>();

const pageSize = 10;
const offset = ref(0);
const totalPages = ref(0);
const positionsLoading = ref(false);
const materialReportPositions = ref<MaterialReportPositionResponse[]>([]);

async function onEditMaterialReportPosition(id: string) {
  if (props.locked) return;
  await router.push({name: routeNames.EDIT_MATERIAL_REPORT, params: {id: id}, query: {subprojectId: props.subprojectId, projectId: props.projectId}})
}

async function onAddMaterialReportPosition() {
  await router.push({name: routeNames.CREATE_MATERIAL_REPORT, query: {subprojectId: props.subprojectId, projectId: props.projectId}})
}

function decodeUnicodeEscapeSequence(str: string): string {
  return str.replace(/\\u[\dA-F]{4}/gi, function (match) {
    return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
  });
}

async function onNextPage() {
  if (offset.value + 1 >= totalPages.value) return;
  offset.value += 1;
  await loadMaterialReportPositions();
}

async function onPreviousPage() {
  if (offset.value == 0) return;
  offset.value -= 1;
  await loadMaterialReportPositions()
}

async function loadMaterialReportPositions(setLoading: boolean = false) {
  if (setLoading) positionsLoading.value = true;
  const response = await getMaterialReportPage(props.subprojectId, pageSize, offset.value);
  positionsLoading.value = false;
  if (!response) return;
  totalPages.value = response.totalPages;
  materialReportPositions.value = response.page;
}

watch(() => props.subprojectId, newVal => {
  if (!newVal) return;
  offset.value = 0;
  loadMaterialReportPositions(true);
}, {
  immediate: true
});

</script>

<style scoped>

</style>