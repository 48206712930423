<template>
  <div class="flex flex-row transition-all py-3 px-7 border-t border-l border-r border-grey-mid rounded-t-sm cursor-pointer hover:border-primary hover:shadow-inner hover:text-primary" :class="{'border-b rounded-b-sm': !extended, 'mt-betweenElements': index != 0, 'border-primary': extended}"
       @click="emits('on-extend', index);">
    <div>{{ selectedPresetId ? `${presets.find(preset => preset.id == selectedPresetId)?.quantity} x ${presets.find(preset => preset.id == selectedPresetId)?.name}` : (workerName ? `${workerQuantity} x ${workerName}` : 'Neuer Arbeiter')
      }} {{ `  -  ${hoursWorked} ${Type.getImmutableUnit(Units.HOURS).abbreviation()}` }}</div>
    <base-icon v-if="deleteEnabled" @click.stop class="ml-auto cursor-pointer text-primary" @click="emits('on-delete', index);">delete</base-icon>
  </div>
  <div v-if="extended" class="rounded-b-sm border border-b-primary border-l-primary border-r-primary border-t-grey-mid p-11">
    <FormKit type="select" name="presetId" id="presetId" label="Vorlage" :options="presetOptions" v-model="selectedPresetId" />
    <FormKit v-if="!selectedPresetId" type="text" name="name" id="name" label="Name*" validation="required" v-model="workerName" />
    <div v-for="(times, index) of selectedTimes" :key="index" class="flex flex-row">
      <div class="mr-betweenElements">
        <FormKit type="select" :name="'fromTime' + index" :id="'fromTime' + index" :label="index == 0 ? 'Von': ''" :options="allTimeOptions" :value="times.from" class="flex-1 mr-6" @input="updateTime(index, $event, 'from')"/>
      </div>
      <FormKit type="select" :name="'toTime' + index" :id="'toTime' + index" :label="index == 0 ? 'Bis': ''" :options="allTimeOptions" :value="times.to" class="flex-1" @input="updateTime(index, $event, 'to')"/>
    </div>
    <div class="flex flex-row">
      <div class="mr-betweenElements">
        <FormKit type="select" name="fromTime" id="fromTime" :label="selectedTimes.length == 0 ? 'Von' : ''" :options="adaptedTimeOptions" v-model="fromTimeSelected" @change="onNewTimeSelected()"/>
      </div>
      <FormKit type="select" name="toTime" id="toTime" :label="selectedTimes.length == 0 ? 'Bis' : ''" :options="adaptedTimeOptions" v-model="toTimeSelected" @change="onNewTimeSelected()"/>
    </div>
    <FormKit type="number" number step="0.25" inputmode="decimal" name="hoursWorked" id="hoursWorked" label="Anzahl Stunden pro Arbeiter*" validation="required" v-model="hoursWorked" class="flex-1"/>
    <FormKit v-if="!selectedPresetId" type="number" inputmode="decimal" number step="any" name="pricePerHour" id="pricePerHour" label="Preis pro Stunde*" validation="required" v-model="pricePerHour" />
    <FormKit v-if="!selectedPresetId" type="number" inputmode="decimal" number="integer" name="workerQuantity" id="workerQuantity" label="Anzahl Arbeiter*" validation="required" v-model="workerQuantity" />
  </div>
</template>

<script setup lang="ts">

import { ref, watch, watchEffect } from 'vue'
import { useToast } from 'vue-toast-notification'
import type WorkerPreset from '@/model/WorkerPreset'
import type { WorkerForm } from '@/model/WorkerForm'
import { Units, Type } from '../../model/Type'

const props = defineProps<{
  index: number,
  worker: WorkerForm,
  presets: WorkerPreset[],
  presetOptions: {value: string, label: string}[],
  extended: boolean,
  deleteEnabled: boolean
}>();

const selectedPresetId = ref('');
const workerName = ref('');
const hoursWorked = ref(0.0);
const pricePerHour = ref(0.0);
const workerQuantity = ref(1);

const adaptedTimeOptions = ref(getTimeValues(16))
const allTimeOptions = ref(getTimeValues(16))
const fromTimeSelected = ref<number>(0);
const toTimeSelected = ref<number>(0);
const selectedTimes = ref<{from: number, to: number}[]>([]);

const $toast = useToast();

const emits = defineEmits(['update-worker', 'on-extend', 'on-delete']);

const watchChanges = ref(true);

watchEffect(() => {
  emits('update-worker',  props.index, {
    id: props.worker ? props.worker.id : 0,
    workReportPositionId: props.worker ? props.worker.workReportPositionId : 0,
    presetId: selectedPresetId.value,
    name: workerName.value,
    hoursWorked: hoursWorked.value,
    pricePerHour: pricePerHour.value,
    quantity: workerQuantity.value
  });
});

watch(() => props.worker, newVal => {
  if (!newVal) return;
  selectedPresetId.value = newVal.presetId;
  workerName.value = newVal.name;
  hoursWorked.value = newVal.hoursWorked;
  pricePerHour.value = newVal.pricePerHour;
  workerQuantity.value = newVal.quantity;
}, {
  immediate: true
});












//Time calculation
function getTimeValues(start: number): {value: number, label: string}[] {
  const result: {value: number, label: string}[] = [];
  result.push({value: 0, label: 'Zeit wählen'})
  for (let i = start; i <= 24*4; i++) {
    result.push({value: i, label: `${i / 4 < 10 ? '0' : ''}${Math.floor(i / 4)}:${getMinutes(i)}`})
  }
  return result;
}

function getMinutes(i: number) {
  switch (i % 4) {
    case 0: return '00';
    case 1: return '15';
    case 2: return '30';
    case 3: return '45';
  }
}

function onNewTimeSelected() {
  if (fromTimeSelected.value == 0) return;
  if (toTimeSelected.value == 0) {
    adaptedTimeOptions.value = getTimeValues(fromTimeSelected.value);
    return;
  }
  if (toTimeSelected.value - fromTimeSelected.value < 0) {
    $toast.error('Von-Zeit kleiner als Bis-Zeit');
    console.error('from time larger than to time');
    return;
  }

  selectedTimes.value.push({from: fromTimeSelected.value, to: toTimeSelected.value});
  fromTimeSelected.value = 0;
  toTimeSelected.value = 0;
  calculateTime();
}

function updateTime(index: number, value: number | undefined, field: string) {
  if (!value) return;
  if (field === 'from') {
    selectedTimes.value[index].from = value;
  } else {
    selectedTimes.value[index].to = value;
  }

  selectedTimes.value = [...selectedTimes.value];
  calculateTime();
}

function calculateTime() {
  let totalTime = 0;
  selectedTimes.value.forEach(times => {
    totalTime += (times.to - times.from) * 0.25;
  })
  hoursWorked.value = totalTime;
  adaptedTimeOptions.value = getTimeValues(selectedTimes.value[selectedTimes.value.length - 1].to);
}

</script>

<style scoped>

</style>