import { API, PathSegment } from '@/client/axios'
import type { WorkReportPositionMinimal } from '@/model/WorkReportPosition'
import { ResponseKey } from '@/model/ResponseWrapper'
import { useToast } from 'vue-toast-notification'
import { Utils } from '@/client/utils'
import type { Page } from '@/model/Page'

const $toast = useToast();

export async function getWorkReportPage(subprojectId: string, pageSize: number, offset: number) {
  try {
    const response = await API.getWithParameters<Page<WorkReportPositionMinimal>>(
      PathSegment.SUBPROJECTS,
      subprojectId,
      PathSegment.WORK_REPORT_POSITIONS,
      new Map<string, string>([['pageSize', `${pageSize}`], ['offset', `${offset}`]])
    );
    if (response.key == ResponseKey.OK) return response.data;
    $toast.error(`Arbeitsrapport konnte nicht geladen werden: ${response.message}`);
  } catch (error) {
    $toast.error(`Arbeitsrapport konnte nicht geladen werden: ${Utils.getError(error)}`);
  }
}