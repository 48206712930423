import type Settings from '@/model/Settings'
import { UseCase } from '@/model/Settings'
import { ObjectStorage, StorageKey } from '@/service/ObjectStorage'

type SettingsEmitter = (settings: Settings) => undefined;
export class TenantSettings {

  private static settings: Settings;
  private static settingsEmitters: SettingsEmitter[] = [];

  static get get() {
    if (!this.settings) this.loadSettingsFromStore();
    return this.settings;
  }

  static setSettings(settings: Settings) {
    this.setAndEmitSettings(settings);
  }

  static loadSettingsFromStore() {
    const loaded = ObjectStorage.get<Settings>(StorageKey.SETTINGS);
    if (!loaded) return false;
    this.setAndEmitSettings(loaded);
    return true;
  }

  static register(settingsEmitter: SettingsEmitter) {
    this.settingsEmitters.push(settingsEmitter);
    if (this.settings) settingsEmitter(this.settings);
  }

  static isTransport() {
    return this.settings.useCase == UseCase.TRANSPORT;
  }

  static isConstruction() {
    return this.settings.useCase == UseCase.CONSTRUCTION;
  }

  static isCustomerOriented() {
    return this.settings.useCase == UseCase.TRANSPORT;
  }

  static isProjectOriented() {
    return this.settings.useCase == UseCase.CONSTRUCTION;
  }

  private static setAndEmitSettings(settings: Settings) {
    this.settings = settings;
    this.settingsEmitters.forEach(emit => emit(this.settings));
  }
}