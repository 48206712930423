<template>

  <base-container>
    <base-heading>
      <div class="title-icon-with-background">
        <base-icon>attach_money</base-icon>
      </div>
      Preis
    </base-heading>

    <FormKit type="select" label="Einheit*" :options="Utils.toSelectOptions(Type.getAllUnits(), 'Einheit wählen')" v-model="selectedUnitId"></FormKit>
    <FormKit type="number" number inputmode="decimal" :label="`Preis pro ${selectedUnitId ? Type.getUnit(selectedUnitId).abbreviation() : 'Einheit'}*`" v-model="unitPrice"></FormKit>
    <FormKit
      type="text"
      id="calcInput"
      inputmode="decimal"
      :label="`Anzahl ${selectedUnitId ? Type.getUnit(selectedUnitId).abbreviation() : 'Einheit'}*`"
      v-model="amountInput"
      :help="amountHelp"
      @focus="showOperations = true"
      @blur="handleBlur"
    />

    <div v-show="showOperations && !isFirefox" ref="operationBar" class="md:hidden flex justify-around">
      <base-button type="icon" look="secondary" @click="addToInput('+')" class="py-2 px-10 text-titleMedium">+</base-button>
      <base-button type="icon" look="secondary" @click="addToInput('-')" class="py-2 px-10 text-titleMedium">-</base-button>
      <base-button type="icon" look="secondary" @click="addToInput('x')" class="py-2 px-10 text-titleMedium">×</base-button>
      <base-button type="icon" look="secondary" @click="addToInput(':')" class="py-2 px-10 text-titleMedium">÷</base-button>
    </div>

    <!-- Fixed bar at the bottom for Firefox since Firefox is the only mobile Browser that does not overlay the virtual
         keyboard over the web content but actually resizes the page. As long as a no solution like maybe VirtualKeyboard API
         is available for all browsers this is a Firefox feature only-->
    <div v-show="showOperations && isFirefox" class="md:hidden fixed inset-x-0 bottom-0 p-4 bg-white flex justify-around border-t border-grey-mid">
      <base-button type="icon" look="secondary" @click="addToInput('+')" class="py-2 px-10 text-titleMedium">+</base-button>
      <base-button type="icon" look="secondary" @click="addToInput('-')" class="py-2 px-10 text-titleMedium">-</base-button>
      <base-button type="icon" look="secondary" @click="addToInput('x')" class="py-2 px-10 text-titleMedium">×</base-button>
      <base-button type="icon" look="secondary" @click="addToInput(':')" class="py-2 px-10 text-titleMedium">÷</base-button>
    </div>

  </base-container>

</template>

<script setup lang="ts">
import BaseIcon from '@/components/base/BaseIcon.vue'
import BaseContainer from '@/components/base/BaseContainer.vue'
import type Category from '@/model/Category'
import { computed, nextTick, onMounted, onUnmounted, ref, watch, watchEffect } from 'vue'
import { Utils } from '@/client/utils'
import { Type } from '@/model/Type'
import type { MaterialReportPositionResponse } from '@/model/MaterialReportPosition'
import { ExpressionCalculator } from '@/service/ExpressionCalculator'
import BaseButton from '@/components/base/BaseButton.vue'


const props = defineProps<{
  category: Category | null;
  existingMaterialReportPosition: MaterialReportPositionResponse | undefined
}>();

const amountInput = ref('');
const amount = ref(0);
const amountHelp = ref('');
const amountInputValid = ref(false);

const selectedUnitId = ref('');
const unitPrice = ref(0);
const showOperations = ref(false);
let operationsButtonClicked = false;

const userAgent = navigator.userAgent;
const isFirefox = computed(() => {
  return userAgent.includes('Firefox');
});

const emits = defineEmits(['update-price']);

watchEffect(() => {
  emits('update-price', {
    amount: amount.value,
    unitId: selectedUnitId.value,
    unitPrice: unitPrice.value
  });
});

watch(() => props.category, newVal => {
  if (!newVal || newVal.id == undefined) return;
  selectedUnitId.value = newVal.standardUnitId ?? '';
  unitPrice.value = newVal.standardPrice ?? 0;
}, {
  immediate: true
});

watch(() => props.existingMaterialReportPosition, newVal => {
  if (!newVal) return;
  amount.value = newVal.quantity;
  amountInput.value = `${newVal.quantity}`;
  selectedUnitId.value = newVal.unitId;
  unitPrice.value = newVal.unitPrice;
}, {
  immediate: true
});

watch(() => amountInput.value, newVal => {
  console.log(newVal);
  const result = ExpressionCalculator.calculate(newVal);
  amountInputValid.value = !!result;
  amount.value = result ?? 0;
  amountHelp.value = result != null ? `= ${amount.value}` : 'Eingabe Fehlerhaft';
}, {
  immediate: true
});

function addToInput(symbol: string) {
  operationsButtonClicked = true;
  amountInput.value += amountInput.value == '' ? symbol : ` ${symbol} `;
  nextTick(() => {
    const inputElement = document.getElementById('calcInput');
    if (inputElement) {
      inputElement.focus();  // Refocus on the input element
    }
  });
}

function handleBlur() {
  setTimeout(() => {
    if (operationsButtonClicked) {
      operationsButtonClicked = false;
      return;
    }
    showOperations.value = false;
  }, 100);  // Delay the hiding to allow button click to execute addToInput
}

</script>

<style scoped>

</style>