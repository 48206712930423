<template>
  <div v-if="isVisible" class="modal-container" @click="closeModal">
    <div class="modal-tall-content flex flex-col" @click.stop>

      <!-- Title-->
      <div class="p-8 text-title">Rechnungs formatierung</div>

      <!-- Main Content -->
      <div class="px-8 overflow-y-auto flex-grow" style="padding-bottom: 100px;">
        <div class="border border-grey-mid rounded p-8 mb-betweenElements">
          <div class="text-bodyMediumBold mb-betweenElements">Arbeitsrapport-Tabelle</div>
          <FormKit type="checkbox" name="pageBreakAfterWork" id="pageBreakAfterWork" label="Tabelle auf neuer Seite" :disabled="generating" v-model="pageBreakBeforeWork" @input="onChange"/>
          <div class="flex md:flex-row flex-col">
            <div class="md:mr-betweenElements"><FormKit type="number" number label="Abstand vor Tabelle" :disabled="pageBreakBeforeWork || generating" v-model="marginBeforeWork" @input="onChange"/></div>
            <FormKit type="number" number label="Abstand nach Tabelle" :disabled="generating" v-model="marginAfterWork" @input="onChange"/>
          </div>
        </div>
        <div class="border border-grey-mid rounded p-8 mb-betweenElements">
          <div class="text-bodyMediumBold mb-betweenElements">Materialrapport-Tabelle</div>
          <FormKit type="checkbox" name="pageBreakAfterWork" id="pageBreakAfterWork" label="Tabelle auf neuer Seite" :disabled="generating" v-model="pageBreakBeforeMaterial" @input="onChange"/>
          <div class="flex md:flex-row flex-col">
            <div class="md:mr-betweenElements"><FormKit type="number" number label="Abstand vor Tabelle" :disabled="pageBreakBeforeMaterial || generating" v-model="marginBeforeMaterial" @input="onChange"/></div>
            <FormKit type="number" number label="Abstand nach Tabelle" :disabled="generating" v-model="marginAfterMaterial" @input="onChange"/>
          </div>
        </div>
        <FormKit type="checkbox" label="Betrag in QR-Rechnung aufführen" :disabled="generating" v-model="amountPresentOnQrBill" @input="onChange"/>
        <FormKit type="checkbox" label="Totalbetrag und QR-Rechnung auf der selben Seite" :disabled="generating" v-model="totalAndQrBillOnSameSite" @input="onChange"/>
        <FormKit type="checkbox" label="Kompakte Rechnung (Beta)" :disabled="generating" v-model="compactBill" @input="onChange"/>
      </div>

      <!-- Buttons -->
      <div class="absolute inset-x-0 bottom-0 bg-white rounded-md">
        <div class="flex">
          <template v-if="!changed && pdf">
            <base-button type="icon" class="flex-1 m-8" @click="onOpen">
              <base-icon class="mr-2">open_in_new</base-icon>
              Öffnen
            </base-button>
            <base-button type="icon" class="flex-1 m-8" @click="onDownload">
              <base-icon class="mr-2">download</base-icon>
              Herunterladen
            </base-button>
          </template>
          <template v-else>
            <base-button type="icon" class="flex-1 m-8" look="secondary" @click="closeModal">
              Abbrechen
            </base-button>

            <base-button type="icon" v-if="!pdf" class="flex-1 m-8" @click="onConfirm">
              <spinner-small v-if="generating" />
              <div v-else>Generieren</div>
            </base-button>
          </template>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup lang="ts">
import BaseButton from '@/components/base/BaseButton.vue'
import { onMounted, ref, watch } from 'vue'
import SpinnerSmall from '@/components/base/SpinnerSmall.vue'
import { PdfService } from '@/service/PdfService'
import { API, PathSegment } from '@/client/axios'
import type InvoiceInfo from '@/model/InvoiceInfo'
import { ResponseKey } from '@/model/ResponseWrapper'
import { Utils } from '@/client/utils'
import type Subproject from '@/model/Subproject'
import BaseIcon from '@/components/base/BaseIcon.vue'

const props = defineProps<{
  subproject: Subproject | undefined
}>();

const isVisible = ref(false);
const generating = ref(false);
const changed = ref(true);

// forms
const amountPresentOnQrBill = ref(false);
const marginBeforeWork = ref(30);
const marginAfterWork = ref(30);
const pageBreakBeforeWork = ref(false);
const marginBeforeMaterial = ref(0);
const marginAfterMaterial = ref(30);
const pageBreakBeforeMaterial = ref(false);
const totalAndQrBillOnSameSite = ref(false);
const compactBill = ref(false);
const pdf = ref<undefined | {pdf: any, name: string}>(undefined);

const emits = defineEmits(['on-close']);

const openModal = (): void => {
  isVisible.value = true;
};

const closeModal = (): void => {
  pdf.value = undefined;
  generating.value = false;
  emits('on-close');
  changed.value = true;
  isVisible.value = false;
};

watch(() => props.subproject, newVal => {
  if (!newVal) return;
  amountPresentOnQrBill.value = newVal.skonto <= 0;
}, {
  immediate: true
});

async function onConfirm() {
  if (generating.value || !props.subproject) return;
  generating.value = true;
  changed.value = false;
  if (!props.subproject) return;
  const response = await getInvoiceInfo(props.subproject.id);
  if (!response) {
    generating.value = false;
    return;
  }
  pdf.value = await PdfService.generateInvoice(response, {
    pageBreakBeforeWork: pageBreakBeforeWork.value,
    marginBeforeWork: marginBeforeWork.value,
    marginAfterWork: marginAfterWork.value,
    pageBreakBeforeMaterial: pageBreakBeforeMaterial.value,
    marginBeforeMaterial: marginBeforeMaterial.value,
    marginAfterMaterial: marginAfterMaterial.value,
    totalAndQrBillOnSameSite: totalAndQrBillOnSameSite.value,
    compactBill: compactBill.value
  });
  generating.value = false;
}

function onOpen() {
  if (!pdf.value) return;
  pdf.value.pdf.open();
}

function onDownload() {
  if (!pdf.value) return;
  pdf.value.pdf.download(pdf.value.name);
  closeModal();
}

async function getInvoiceInfo(subprojectId: string) {
  try {
    const response = await API.getWithParameters<InvoiceInfo>(PathSegment.SUBPROJECTS, subprojectId, PathSegment.INVOICE_INFO, new Map<string,string>([['qrAmountPresent', `${amountPresentOnQrBill.value}`]]));
    if (response.key == ResponseKey.OK) {
      return response.data;
    } else {
      API.logApiError('Rechnugnsinformation', 'geladen', response.message, response.key);
    }
  } catch (error) {
    API.logApiError('Rechnugnsinformation', 'geladen', Utils.getError(error));
  }
}

function onChange() {
  changed.value = true;
  pdf.value = undefined;
}

onMounted(() => {

})

defineExpose({
  openModal,
  closeModal
});
</script>

<style scoped>

</style>