export default interface Settings {
  tenant: string,
  pricesIncludingVat: boolean
  distanceTopLogo: number,
  distanceLogoAddress: number,
  logoHeight: number,
  distanceBetweenAddresses: number,
  paymentDeadline: number,
  skontoRuntime: number,
  invoiceTitle: string,
  invoiceHeader?: string,
  invoiceFooter?: string,
  invoiceWorkTableTitle: string,
  invoiceMaterialTableTitle: string,
  aiLanguage: string,
  aiPrompt: string,
  updatedAt: number,
  useCase: UseCase
}

export enum UseCase {
  UNDEFINED = 'UNDEFINED',
  CONSTRUCTION = 'CONSTRUCTION',
  TRANSPORT = 'TRANSPORT'
}