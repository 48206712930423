<template>

  <div class="flex-col my-auto" :class="{'md:max-w-containerWidth max-w-full': narrow}">

    <div class="flex flex-row my-outerFrameBorder items-center">
      <div class="pr-2 whitespace-nowrap overflow-hidden text-ellipsis min-w-0">
        <p v-if="subtitleAbove" class="cursor-pointer hover:text-primary md:ml-0 ml-outerFrameBorder text-bodyMediumBold pt-2 whitespace-nowrap overflow-hidden text-ellipsis min-w-0" @click="emits('subtitle-above-action')">{{subtitleAbove}}</p>
        <h1 class="md:ml-0 ml-outerFrameBorder text-start py-1 mr-auto md:text-titleLarge text-titleMedium xs:font-bold overflow-hidden whitespace-nowrap text-ellipsis min-w-0">{{title}}</h1>
        <p v-if="subtitleBelow" class="md:ml-0 ml-outerFrameBorder text-bodyMediumBold pt-2">{{subtitleBelow}}</p>
      </div>
      <button
        v-if="showButton"
        @click="emits('button-action')"
        :class="`flex items-center justify-center btn md:mr-0 mr-outerFrameBorder h-15 w-15 ml-auto btn-${buttonLook}`">
        <base-icon class="text-center">{{ buttonIcon }}</base-icon>
      </button>
    </div>

    <slot></slot>

  </div>

</template>

<script setup lang="ts">
import BaseIcon from '@/components/base/BaseIcon.vue'
import { button } from '@formkit/icons'

const props = withDefaults(defineProps<{
  title: string,
  subtitleAbove?: string,
  subtitleBelow?: string,
  narrow?: boolean,
  showButton?: boolean,
  buttonLook?: string,
  buttonIcon?: string
}>(), {
  narrow: true,
  showButton: false,
  buttonLook: 'primary',
  buttonIcon: 'edit'
});

const emits = defineEmits(['button-action', 'subtitle-above-action']);

</script>

<style scoped>

</style>